import React, { useEffect } from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import "./AddHospitals.css";
import "../../../Assets/Css/Responsive.css";
import { useForm } from "react-hook-form";
import Input from "../../../Components/InputComponent/Input";
import ButtonComponent from "../../../Components/ButtonComponent/ButtonComponent";
import { GetApi, postApi } from "../../../Api/Api";
import toast from "react-hot-toast";
import { HOSPITAL_ROUTE } from "../../../Api/Routes";

const AddHospitals = (props) => {
  const { handleClose, open, editValue, hospitalId, AddUpdateHospital } = props;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (editValue) {
      const fetchHospitalDetails = async () => {
        try {
          const response = await GetApi(`${HOSPITAL_ROUTE}/${hospitalId}`);
          const hospitalDetails = response.data;
          setValue("name", hospitalDetails.name);
          setValue("phone_no", hospitalDetails.phone_no);
          setValue("email_id", hospitalDetails.email_id);
          setValue("address", hospitalDetails.address);
        } catch (error) {
          console.error("Error fetching hospital details:", error);
        }
      };
      fetchHospitalDetails();
    }
  }, [editValue, hospitalId, setValue]);

  useEffect(() => {
    if (!editValue && open) {
      reset();
    }
  }, [editValue, open, reset]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const endpoint = editValue
      ? `${HOSPITAL_ROUTE}/${hospitalId}`
      : `${HOSPITAL_ROUTE}`;

    data.is_deleted = false;

    try {
      const responseData = await postApi(
        endpoint,
        editValue ? { ...data, id: hospitalId } : data
      );
      if (responseData?.status === 200) {
        if (responseData?.data) {
          reset();
          handleClose();
          toast.success(editValue ? "Hospital Updated" : "Hospital Added");
          AddUpdateHospital();
        } else {
          toast.error(
            editValue ? "Hospital Update Failed" : "Hospital Creation Failed."
          );
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="modal-shadow">
            <Box className="modal-header">
              <Typography
                id="modal-modal-title"
                className="text-primery"
                variant="h4"
                component="h4"
              >
                {editValue ? "Update Hospital" : "Add Hospital"}
              </Typography>
              <Button onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </Box>
            <Box
              className="modal-body"
              id="modal-modal-description"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Hospital Name"
                        placeholder="Apollo"
                        type="text"
                        name="name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: "Hospital Name is required",
                          maxLength: {
                            value: 512,
                            message: "Maximum 256 characters are allowed.",
                          },
                        }}
                      />
                      {errors.name && (
                        <p className="error-message">{errors.name.message}</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Phone Number"
                        placeholder="963214587"
                        type="number"
                        name="phone_no"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: "Phone Number is required",
                        }}
                      />
                      {errors.phone_no && (
                        <p className="error-message">
                          {errors.phone_no.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="apollo@gmail.com"
                        type="email"
                        name="email_id"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: "Email ID is required",
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message:
                              "Invalid email format. Please enter a valid email address.",
                          },
                        }}
                      />
                      {errors.email_id && (
                        <p className="error-message">
                          {errors.email_id.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Address"
                        placeholder="Pitts"
                        type="text"
                        name="address"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: "Address is required",
                          maxLength: {
                            value: 512,
                            message: "Maximum 256 characters are allowed.",
                          },
                        }}
                      />
                      {errors.address && (
                        <p className="error-message">
                          {errors.address.message}
                        </p>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-center">
                        <ButtonComponent
                          type="submit"
                          buttonText={
                            editValue ? "Update Hospital" : "Add Hospital"
                          }
                          buttonVariant="contained"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddHospitals;

const style = {
  position: "absolute",
  top: "50%",
  left: 15,
  right: 15,
  transform: "translateY(-50%)",
  maxWidth: 1000,
  color: "#ffffff",
  boxShadow: 24,
  margin: "1rem auto",
  maxHeight: "100%",
};
