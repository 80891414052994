// AppRouter.js
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "../Screens/Dashboard/Dashboard";
import MyPatients from "../Screens/MyPatients/MyPatients";
import Login from "../Screens/Login/Login";
import Layout from "../Layouts/Layout/Layout";
import PrivateRoutes from "./PrivateRoutes";
import Hospitals from "../Screens/Hospital/Hospital";
import Physician from "../Screens/Physician/Physician";
import User from "../Screens/User/User";
import SetGoalComponent from "../Screens/SetGoalComponent/SetGoalComponent";
import Controllers from "../Screens/Controller/Controller";
import ForgotPassword from "../Screens/Login/ForgotPassword";
import MyPatientDetails from "../Screens/MyPatients/MyPatientDetails";
import { AuthProvider } from "../Context/AuthContext";
import ResetPassword from "../Screens/Login/ResetPassword";
import CheckEmail from "../Screens/Login/CheckEmail";
import LoginRoutes from "./LoginRoutes";
import { basePath } from "./Config";
import toast from "react-hot-toast";

const adminRoutes = [
  {
    path: basePath,
    element: <Layout />,
    children: [
      {
        path: "hospitals",
        element: <PrivateRoutes component={Hospitals} roles={["admin"]} />,
      },
      {
        path: "physicians",
        element: <PrivateRoutes component={Physician} roles={["admin"]} />,
      },
      {
        path: "admins",
        element: <PrivateRoutes component={User} roles={["admin"]} />,
      },
    ],
  },
];

const commonRoutes = [
  {
    path: basePath,
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoutes component={Dashboard} roles={["admin", "physician"]} />
        ),
      },
      {
        path: "patients",
        element: (
          <PrivateRoutes
            component={MyPatients}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "goals-setup",
        element: (
          <PrivateRoutes
            component={SetGoalComponent}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "patients/:id",
        element: (
          <PrivateRoutes
            component={MyPatientDetails}
            roles={["admin", "physician"]}
          />
        ),
      },
      {
        path: "devices",
        element: (
          <PrivateRoutes
            component={Controllers}
            roles={["admin", "physician"]}
          />
        ),
      },
    ],
  },
];

const AppRouter = () => {
  const location = useLocation();

  useEffect(() => {
    toast.remove();
  }, [location]);

  return (
    <AuthProvider>
      <Routes>
        {commonRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {Array.isArray(route.children) &&
              route.children.map((childRoute, childIndex) => (
                <Route key={childIndex} {...childRoute} />
              ))}
          </Route>
        ))}

        {adminRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {Array.isArray(route.children) &&
              route.children.map((childRoute, childIndex) => (
                <Route key={childIndex} {...childRoute} />
              ))}
          </Route>
        ))}

        <Route
          path={`${basePath}login`}
          element={<LoginRoutes component={Login} />}
        />
        <Route
          path={`${basePath}forget-password`}
          element={<LoginRoutes component={ForgotPassword} />}
        />
        <Route
          path={`${basePath}reset-password/:token`}
          element={<LoginRoutes component={ResetPassword} />}
        />
        <Route
          path={`${basePath}email-link`}
          element={<LoginRoutes component={CheckEmail} />}
        />
      </Routes>
    </AuthProvider>
  );
};

export default AppRouter;
