import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  EVENT_LINE_PADDING,
  TIMELINE_TOP_PADDING,
  TIMELINE_BOTTOM_PADDING,
  TIMELINE_SIDE_PADDING,
  BASELINE_HEIGHT,
  EVENT_LINE_HEIGHT,
  TIMELIME_AXIS_THICKNESS,
  TIMELINE_TICK_THICKNESS,
  TIMELINE_TICK_HEIGHT,
  TIMELINE_SHORT_TICK_HEIGHT,
  TIMELINE_TICK_LABEL_SPACING,
  TIMELINE_EVENT_OUTLINE_THICKNESS,
  TIMELINE_TOOLTIP_OUTLINE_THICKNESS,
  TIMELINE_TOOLTIP_PADDING,
  TIMELINE_TIME_OFFSET_X,
  TIMELINE_TIME_OFFSET_Y,
  TIMELINE_MIN_X,
  TIMELINE_COLORS,
  MIN_HOUR_LABEL_SPACING,
  SHORT_TICK_MIN_SPACING,
  SHORT_TICK_INCREMENT_OPTIONS,
} from "./constants";

const TimelineChart = ({ data, hiddenEvents }) => {
  const timeline_start_minute = data?.start_minute;
  const timeline_minutes = data?.minutes;

  // Layout events
  const [events, max_row] = useMemo(() => {
    const rawEvents = data?.events;

    let maxRow = 0;
    const events = [];
    const activeEvents = [];
    for (let i = 0; i < rawEvents.length; i++) {
      const event = rawEvents[i];

      // Skip hidden events
      if (hiddenEvents.includes(event.text)) continue;

      if (event.base) {
        // Base sleep state events (eg. Awake, Asleep, Out of bed, Out of room) events will always be mutually exclusive & exist on the baseline
        events.push({
          ...event,
          row: 0,
        });
      } else {
        // Other events will be automatically stacked above the baseline

        // Update the activeEvents list based on the start time of this event
        // and add this event to an empty row if there is one
        let assignedRow = undefined;
        for (let j = 0; j < activeEvents.length; j++) {
          // Check if this event has ended
          if (activeEvents[j] !== null && activeEvents[j].end <= event.start) {
            activeEvents[j] = null;
          }

          // Check if the row is empty
          if (activeEvents[j] === null) {
            assignedRow = j + 1;
            activeEvents[j] = event;
            break;
          }
        }

        // No empty row was found, add the event to a new row
        if (assignedRow === undefined) {
          assignedRow = activeEvents.length + 1;
          activeEvents.push(event);
        }

        // Add the event to the results
        events.push({
          ...event,
          row: assignedRow,
        });

        // Update the maxRow
        maxRow = Math.max(maxRow, assignedRow);
      }
    }

    return [events, maxRow];
  }, [data, hiddenEvents]);

  const canvasRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!events) return;

    const handleMouseMove = (event) => {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      setMousePosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    };

    const canvas = canvasRef.current;
    canvas.addEventListener("mousemove", handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [events]);

  const draw = useCallback(
    (ctx) => {
      canvasRef.current.width =
        document.getElementById("timeline-container").clientWidth;

      // --- Timeline initialization code ---
      const TIMELINE_HEIGHT =
        TIMELINE_BOTTOM_PADDING +
        EVENT_LINE_PADDING +
        BASELINE_HEIGHT +
        (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * max_row +
        TIMELINE_TOP_PADDING;

      canvasRef.current.height = TIMELINE_HEIGHT;

      ctx.font = '14px "Arial"';
      const TIMELINE_WIDTH = canvasRef.current.width - 2 * TIMELINE_MIN_X;

      const timelineMouseX = mousePosition.x;
      const timelineMouseY = mousePosition.y;

      ctx.fillStyle = TIMELINE_COLORS.BG2;
      ctx.fillRect(0, 0, TIMELINE_WIDTH, TIMELINE_HEIGHT);

      const TIMELINE_AXIS_Y = TIMELINE_HEIGHT - TIMELINE_BOTTOM_PADDING;
      const BASELINE_Y = TIMELINE_AXIS_Y - EVENT_LINE_PADDING - BASELINE_HEIGHT;
      const TIMELINE_AXIS_WIDTH = TIMELINE_WIDTH - 2 * TIMELINE_SIDE_PADDING;

      ctx.fillStyle = TIMELINE_COLORS.Accent3;
      ctx.fillRect(
        TIMELINE_SIDE_PADDING,
        TIMELINE_AXIS_Y - 0.5 * TIMELIME_AXIS_THICKNESS,
        TIMELINE_AXIS_WIDTH,
        TIMELIME_AXIS_THICKNESS
      );

      // Compute the number of hour labels we need to skip given the current spacing between hour ticks.
      // If `hour_spacing` is half `MIN_HOUR_LABEL_SPACING` then we want to skip every other hour label,
      // meaning `hour_label_increment` will be 2.
      const hour_spacing = (TIMELINE_AXIS_WIDTH * 60) / timeline_minutes;
      const hour_label_increment = Math.ceil(
        MIN_HOUR_LABEL_SPACING / hour_spacing
      );

      // Draw hour ticks
      const timeline_start_hour = Math.ceil(timeline_start_minute / 60);
      const timeline_hour_offset = (60 - (timeline_start_minute % 60)) % 60;
      const timeline_hours = Math.floor(
        (timeline_minutes - timeline_hour_offset) / 60
      );
      for (let i = 0; i <= timeline_hours; i++) {
        const hour_frac = (timeline_hour_offset + 60 * i) / timeline_minutes;

        const x =
          hour_frac * TIMELINE_AXIS_WIDTH -
          0.5 * TIMELINE_TICK_THICKNESS +
          TIMELINE_SIDE_PADDING;
        ctx.fillRect(
          x,
          TIMELINE_AXIS_Y,
          TIMELINE_TICK_THICKNESS,
          TIMELINE_TICK_HEIGHT
        );

        if (i % hour_label_increment === 0) {
          const hour = (i + timeline_start_hour) % 24;
          let labelText = "";
          if (hour === 0) {
            labelText = `12 AM`;
          } else if (hour === 12) {
            labelText = `12 PM`;
          } else if (hour > 12) {
            labelText = `${hour - 12} PM`;
          } else {
            labelText = `${hour} AM`;
          }

          const textMetrics = ctx.measureText(labelText);
          ctx.fillText(
            labelText,
            x - 0.5 * textMetrics.width,
            TIMELINE_AXIS_Y +
              TIMELINE_TICK_HEIGHT +
              textMetrics.actualBoundingBoxAscent +
              TIMELINE_TICK_LABEL_SPACING
          );
        }
      }

      // Draw short ticks if the range is small enough
      const minute_spacing = TIMELINE_AXIS_WIDTH / timeline_minutes;
      let short_tick_increment = undefined;
      for (let i = 0; i < SHORT_TICK_INCREMENT_OPTIONS.length; i++) {
        if (
          minute_spacing * SHORT_TICK_INCREMENT_OPTIONS[i] >
          SHORT_TICK_MIN_SPACING
        ) {
          short_tick_increment = SHORT_TICK_INCREMENT_OPTIONS[i];
          break;
        }
      }

      if (short_tick_increment !== undefined) {
        for (
          let i = 0;
          i < Math.floor(timeline_minutes);
          i += short_tick_increment
        ) {
          const x =
            (i / timeline_minutes) * TIMELINE_AXIS_WIDTH -
            0.5 * TIMELINE_TICK_THICKNESS +
            TIMELINE_SIDE_PADDING;
          ctx.fillRect(
            x,
            TIMELINE_AXIS_Y,
            TIMELINE_TICK_THICKNESS,
            TIMELINE_SHORT_TICK_HEIGHT
          );
        }
      }

      for (let i = 0; i < events.length; i++) {
        const event = events[i];

        const minY =
          BASELINE_Y - (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * event.row;
        const maxY =
          minY + (event.row === 0 ? BASELINE_HEIGHT : EVENT_LINE_HEIGHT);

        const minX =
          TIMELINE_SIDE_PADDING +
          event.start * (TIMELINE_AXIS_WIDTH / timeline_minutes);
        const maxX =
          TIMELINE_SIDE_PADDING +
          event.end * (TIMELINE_AXIS_WIDTH / timeline_minutes);

        if (
          minX < timelineMouseX &&
          timelineMouseX < maxX &&
          minY < timelineMouseY &&
          timelineMouseY < maxY
        ) {
          ctx.fillStyle = TIMELINE_COLORS.Accent2;
          ctx.fillRect(minX, minY, maxX - minX, maxY - minY);

          ctx.fillStyle = event.color;
          ctx.fillRect(
            minX + TIMELINE_EVENT_OUTLINE_THICKNESS,
            minY + TIMELINE_EVENT_OUTLINE_THICKNESS,
            maxX - minX - 2 * TIMELINE_EVENT_OUTLINE_THICKNESS,
            maxY - minY - 2 * TIMELINE_EVENT_OUTLINE_THICKNESS
          );
        } else {
          ctx.fillStyle = event.color;
          ctx.fillRect(minX, minY, maxX - minX, maxY - minY);
        }
      }

      const TIMELINE_MIN_MOUSE_X = TIMELINE_SIDE_PADDING;
      const TIMELINE_MAX_MOUSE_X = TIMELINE_WIDTH - TIMELINE_SIDE_PADDING;
      if (
        TIMELINE_MIN_MOUSE_X <= timelineMouseX &&
        timelineMouseX <= TIMELINE_MAX_MOUSE_X &&
        0 <= timelineMouseY &&
        timelineMouseY <= TIMELINE_HEIGHT
      ) {
        ctx.fillStyle = TIMELINE_COLORS.Accent2;
        ctx.fillRect(
          timelineMouseX - 0.5 * TIMELIME_AXIS_THICKNESS,
          0,
          TIMELIME_AXIS_THICKNESS,
          TIMELINE_AXIS_Y
        );

        const mouseTime =
          (timeline_minutes * (timelineMouseX - TIMELINE_MIN_MOUSE_X)) /
            TIMELINE_AXIS_WIDTH +
          timeline_start_minute;
        const hour = Math.floor(mouseTime / 60) % 24;
        const min = (Math.floor(mouseTime) % 60).toString().padStart(2, "0");
        const date = new Date(data?.startTime);
        date.setSeconds(
          date.getSeconds() + (mouseTime - timeline_start_minute) * 60
        );
        const dateLabel = date.toLocaleDateString("en-US");

        let mouseTimeLabel = "";
        if (hour === 0) {
          mouseTimeLabel = `12:${min} AM`;
        } else if (hour === 12) {
          mouseTimeLabel = `12:${min} PM`;
        } else if (hour > 12) {
          mouseTimeLabel = `${hour - 12}:${min} PM`;
        } else {
          mouseTimeLabel = `${hour}:${min} AM`;
        }
        mouseTimeLabel += ` (${dateLabel})`;

        const textMetrics = ctx.measureText(mouseTimeLabel);

        ctx.fillStyle = TIMELINE_COLORS.Accent3;
        if (
          timelineMouseX + 2 * TIMELINE_TIME_OFFSET_X + textMetrics.width >
          TIMELINE_WIDTH
        ) {
          ctx.fillText(
            mouseTimeLabel,
            timelineMouseX - textMetrics.width - TIMELINE_TIME_OFFSET_X,
            TIMELINE_TIME_OFFSET_Y
          );
        } else {
          ctx.fillText(
            mouseTimeLabel,
            timelineMouseX + TIMELINE_TIME_OFFSET_X,
            TIMELINE_TIME_OFFSET_Y
          );
        }
      }

      ctx.setLineDash([]);
      ctx.lineWidth = TIMELINE_TOOLTIP_OUTLINE_THICKNESS;
      for (let i = 0; i < events.length; i++) {
        const event = events[i];

        const minY =
          BASELINE_Y - (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * event.row;
        const maxY =
          minY + (event.row === 0 ? BASELINE_HEIGHT : EVENT_LINE_HEIGHT);

        const minX =
          TIMELINE_SIDE_PADDING +
          event.start * (TIMELINE_AXIS_WIDTH / timeline_minutes);
        const maxX =
          TIMELINE_SIDE_PADDING +
          event.end * (TIMELINE_AXIS_WIDTH / timeline_minutes);

        // On hover tooltip
        if (
          minX < timelineMouseX &&
          timelineMouseX < maxX &&
          minY < timelineMouseY &&
          timelineMouseY < maxY
        ) {
          const duration = event.end - event.start; // in mins
          let durationLabel = "";
          if (duration < 1) {
            durationLabel = `${(duration * 60).toFixed(2)} s`;
          } else if (duration < 60) {
            durationLabel = `${duration.toFixed(2)} mins`;
          } else {
            durationLabel = `${(duration / 60).toFixed(2)} hrs`;
          }
          const eventText = `${event.text} (${durationLabel})`;
          const textMetrics = ctx.measureText(eventText);
          const tooltipWidth = textMetrics.width + 2 * TIMELINE_TOOLTIP_PADDING;
          const tooltipHeight =
            textMetrics.actualBoundingBoxAscent +
            textMetrics.actualBoundingBoxDescent +
            2 * TIMELINE_TOOLTIP_PADDING;

          if (timelineMouseX + tooltipWidth > TIMELINE_WIDTH) {
            ctx.fillStyle = TIMELINE_COLORS.BG2;
            ctx.fillRect(
              timelineMouseX - tooltipWidth,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.strokeStyle = TIMELINE_COLORS.Accent3;
            ctx.strokeRect(
              timelineMouseX - tooltipWidth,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.fillStyle = TIMELINE_COLORS.Accent3;
            ctx.fillText(
              eventText,
              timelineMouseX + TIMELINE_TOOLTIP_PADDING - tooltipWidth,
              timelineMouseY -
                tooltipHeight +
                textMetrics.actualBoundingBoxAscent +
                TIMELINE_TOOLTIP_PADDING
            );
          } else {
            ctx.fillStyle = TIMELINE_COLORS.BG2;
            ctx.fillRect(
              timelineMouseX,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.strokeStyle = TIMELINE_COLORS.Accent3;
            ctx.strokeRect(
              timelineMouseX,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.fillStyle = TIMELINE_COLORS.Accent3;
            ctx.fillText(
              eventText,
              timelineMouseX + TIMELINE_TOOLTIP_PADDING,
              timelineMouseY -
                tooltipHeight +
                textMetrics.actualBoundingBoxAscent +
                TIMELINE_TOOLTIP_PADDING
            );
          }
        }
      }
    },
    [
      mousePosition.x,
      mousePosition.y,
      events,
      timeline_minutes,
      timeline_start_minute,
      max_row,
      data.startTime,
    ]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const handleResize = () => draw(context);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [draw]);

  return (
    <Box
      display="flex"
      alignItems="center"
      id="timeline-container"
      height="100%"
      pt={2}
    >
      <canvas ref={canvasRef} />
    </Box>
  );
};

export default TimelineChart;
