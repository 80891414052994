import React, { useEffect, useState } from "react";
import "./MyPatients.css";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import { GetApi, GetApiParam } from "../../Api/Api";
import { PatientTable, AddPatients } from "./components";
import { Toaster } from "react-hot-toast";
import { PATIENT_ROUTE, USER_ROUTE } from "../../Api/Routes";

const MyPatients = () => {
  const [open, setOpen] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [physicianData, setPhysicianData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPatientData = async () => {
    try {
      const response = await GetApi(PATIENT_ROUTE);
      const responseUsers = await GetApiParam(USER_ROUTE, { role: "Physician" });

      // map patient.physician_id to physician name
      const patients = response?.data?.patients || [];
      const users = responseUsers?.data?.users || [];

      // filter patients by is_deleted = false
      const activePatients = patients.filter((patient) => !patient.is_deleted)

      activePatients.forEach((patient) => {
        const physician = users.find(
          (user) => user.id === patient.physician_id
        );
        patient.physician_name = `${physician?.first_name} ${physician?.last_name}`;
      });

      setPatientData(activePatients);
      setPhysicianData(users);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!patientData) {
      fetchPatientData();
    }
  }, [patientData]);

  const AddUpdatePatient = () => {
    fetchPatientData();
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px" }} />;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Typography className="title" component="h4">
            Patients
          </Typography>
        </Box>
        <Box className="right btn-font-small">
          <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
            <ButtonComponent
              buttonText="Add Patient"
              buttonVariant="contained"
              startIcon={<AddIcon />}
              buttonStyle={{ borderRadius: "30px" }}
              onClick={handleOpen}
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ mt: "30px" }}>
        {patientData ? (
          <PatientTable
            patientData={patientData || []}
            AddUpdatePatient={AddUpdatePatient}
            physicianData={physicianData || []}
          />
        ) : null}
      </Box>
      <AddPatients
        open={open}
        handleClose={handleClose}
        AddUpdatePatient={AddUpdatePatient}
        physicianData={physicianData || []}
      />
    </Box>
  );
};

export default MyPatients;
