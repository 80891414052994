// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-wrapper button, .btn-wrapper button:hover{
    background-color: var(--primeryColor);
    color: var(--darkGreen);
    font-size: 20px;
    font-weight: bold;
    font-family: 'Acumin Pro';
    width: 100%;
    padding: 0.6rem 1rem;
}
.btn-wrapper button.btn_w_auto{
    width: auto;
    min-width: 150px;
}
.btn-wrapper button.btn-outline-pink {
    background: transparent;
    border: 1px solid var(--pinkColor);
    color: var(--pinkColor);
    width: auto;
    min-width: 90px;
}
.btn-wrapper button.btn-outline-pink:hover{
    background: var(--pinkColor);
    color: var(--green);
}

.btn-wrapper button.btn-small {
    font-size: 8px;
    font-weight: 400;
    padding: 6px 10px;
}
.btn-wrapper button.btn-round{
    border-radius: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ButtonComponent/Button.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,uBAAuB;IACvB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;IACX,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,kCAAkC;IAClC,uBAAuB;IACvB,WAAW;IACX,eAAe;AACnB;AACA;IACI,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,oBAAoB;AACxB","sourcesContent":[".btn-wrapper button, .btn-wrapper button:hover{\n    background-color: var(--primeryColor);\n    color: var(--darkGreen);\n    font-size: 20px;\n    font-weight: bold;\n    font-family: 'Acumin Pro';\n    width: 100%;\n    padding: 0.6rem 1rem;\n}\n.btn-wrapper button.btn_w_auto{\n    width: auto;\n    min-width: 150px;\n}\n.btn-wrapper button.btn-outline-pink {\n    background: transparent;\n    border: 1px solid var(--pinkColor);\n    color: var(--pinkColor);\n    width: auto;\n    min-width: 90px;\n}\n.btn-wrapper button.btn-outline-pink:hover{\n    background: var(--pinkColor);\n    color: var(--green);\n}\n\n.btn-wrapper button.btn-small {\n    font-size: 8px;\n    font-weight: 400;\n    padding: 6px 10px;\n}\n.btn-wrapper button.btn-round{\n    border-radius: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
