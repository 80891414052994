import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { basePath } from "./Config";

const LoginRoutes = ({ component: Component, roles, ...rest }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token) {
      navigate(basePath);
    }
  }, [user, navigate]);

  return <Component />;
};

export default LoginRoutes;
