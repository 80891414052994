// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-contant.profile-page {
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
}
.profile-wrapper {
    background: var(--darkGreen);
    border-radius: 1rem;
    max-width: 900px;
    margin: auto;
}
.profile-inner {
    padding: 1rem 2rem;
}
.profile-header {
    padding: 1rem 1rem 2rem 1rem;
}

.select-form-group label.MuiFormLabel-root {
    position: static;
    transform: none;
}
.select-form-group label.MuiFormLabel-root {
    position: static;
    transform: none;
    font-family: 'Acumin Pro';
    color: rgb(255 255 255/50%);
    margin-bottom: 2px;
}
.select-form-group .MuiFormControl-root .MuiSelect-select{
    padding: 0.789rem 2.3rem 0.789rem 1rem;
    -webkit-text-fill-color: var(--white);
    color: var(--white);
}
.select-form-group .MuiFormControl-root svg {
    fill: rgb(255 255 255/50%);
}
.select-form-group .MuiInputBase-root fieldset,
.select-form-group .MuiInputBase-root:hover fieldset{
    border-color: var(--whiteLight);
}
.select-form-group .MuiInputBase-root.Mui-focused fieldset{
    border-width: 1px;
    border-color: var(--primeryColor);
}`, "",{"version":3,"sources":["webpack://./src/Screens/Profile/Profile.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,4BAA4B;IAC5B,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,2BAA2B;IAC3B,kBAAkB;AACtB;AACA;IACI,sCAAsC;IACtC,qCAAqC;IACrC,mBAAmB;AACvB;AACA;IACI,0BAA0B;AAC9B;AACA;;IAEI,+BAA+B;AACnC;AACA;IACI,iBAAiB;IACjB,iCAAiC;AACrC","sourcesContent":[".main-contant.profile-page {\n    height: calc(100% - 60px);\n    display: flex;\n    align-items: center;\n}\n.profile-wrapper {\n    background: var(--darkGreen);\n    border-radius: 1rem;\n    max-width: 900px;\n    margin: auto;\n}\n.profile-inner {\n    padding: 1rem 2rem;\n}\n.profile-header {\n    padding: 1rem 1rem 2rem 1rem;\n}\n\n.select-form-group label.MuiFormLabel-root {\n    position: static;\n    transform: none;\n}\n.select-form-group label.MuiFormLabel-root {\n    position: static;\n    transform: none;\n    font-family: 'Acumin Pro';\n    color: rgb(255 255 255/50%);\n    margin-bottom: 2px;\n}\n.select-form-group .MuiFormControl-root .MuiSelect-select{\n    padding: 0.789rem 2.3rem 0.789rem 1rem;\n    -webkit-text-fill-color: var(--white);\n    color: var(--white);\n}\n.select-form-group .MuiFormControl-root svg {\n    fill: rgb(255 255 255/50%);\n}\n.select-form-group .MuiInputBase-root fieldset,\n.select-form-group .MuiInputBase-root:hover fieldset{\n    border-color: var(--whiteLight);\n}\n.select-form-group .MuiInputBase-root.Mui-focused fieldset{\n    border-width: 1px;\n    border-color: var(--primeryColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
