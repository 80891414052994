import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import "./Dashboard.css";
import "../../Assets/Css/Responsive.css";
import DashboardCard from "../../Components/DashboardComponents/DashboardCard/DashboardCard";
import mypatients from "../../Assets/Images/Dashboard/my-patients.png";
import patientsbaseline from "../../Assets/Images/Dashboard/patients-baseline.png";
import patientsmaintenance from "../../Assets/Images/Dashboard/patients-maintenance.png";
import patientstreatment from "../../Assets/Images/Dashboard/patients-treatment.png";
import { GetApi } from "../../Api/Api.js";
import { Toaster } from "react-hot-toast";
import ControllersTable from "./components/controllerStatusTable";
import { DEVICE_ROUTE, PATIENT_ROUTE } from "../../Api/Routes.js";

const Dashboard = () => {
  const [controllerData, setControllerData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [assignedCount, setAssignedCount] = useState(0);
  const [unassignedCount, setUnassignedCount] = useState(0);

  const [patientCount, setPatientCount] = useState(0);
  const [baselineCount, setBaselineCount] = useState(0);
  const [treatmentCount, setTreatmentCount] = useState(0);
  const [maintenanceCount, setMaintenanceCount] = useState(0);

  const fetchControllerCounts = async () => {
    try {
      const response = await GetApi(DEVICE_ROUTE);
      if (response?.data?.devices) {
        const data = response.data;

        // filter assigned controller by device?.patient_name !== null
        const assignedCount = data?.devices.filter(
          (device) => device?.patient_name
        ).length;
        const unassignedCount = data?.devices.filter(
          (device) => !device?.patient_name
        ).length;

        setAssignedCount(assignedCount);
        setUnassignedCount(unassignedCount);
      }
    } catch (error) {
      console.error("Error fetching controller counts:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchControllerData = async () => {
    try {
      const response = await GetApi(DEVICE_ROUTE);
      setControllerData(response?.data?.devices);
      setLoading(false);
    } catch (error) {
      // setError(error);
      setLoading(false);
    }
  };

  const fetchPatients = async () => {
    try {
      const response = await GetApi(PATIENT_ROUTE);
      console.log(response)
      if (response.data) {
        setPatientCount(response.data?.patients.length);
        setBaselineCount(response.data?.patients.filter(x => x.start_treatment == null).length);
        setTreatmentCount(response.data?.patients.filter(x => (x.start_treatment != null) && (x.end_treatment == null)).length);
        setMaintenanceCount(response.data?.patients.filter(x => x.end_treatment != null).length);
      }
    } catch (error) {
      console.error("Error fetching patient data: ", error);
    }
  };

  useEffect(() => {
    fetchPatients();
    fetchControllerCounts();
    fetchControllerData();
  }, []);

  if (loading) {
    return <CircularProgress style={{ margin: "20px" }} />;
  }

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              icon={mypatients}
              title="My Patients"
              count={patientCount.toString().padStart(2, '0')}
              customStyle={{ background: "#FFA6C7" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              title="Patients in Baseline"
              icon={patientsbaseline}
              count={baselineCount.toString().padStart(2, '0')}
              customStyle={{ background: "#91D9B9" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              title="Patients in Treatment"
              icon={patientsmaintenance}
              count={treatmentCount.toString().padStart(2, '0')}
              customStyle={{ background: "#FFC548" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCard
              title="Patients in Maintenance"
              icon={patientstreatment}
              count={maintenanceCount.toString().padStart(2, '0')}
              customStyle={{ background: "#FA8352" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="dash-graph-container" mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} xl={8}>
            <Box className="left-section">
              <Typography className="heading" component="h4">
                Device Status
              </Typography>
              <ControllersTable controllerData={controllerData} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} xl={4}>
            <Box className="right-section">
              <Typography className="heading" component="h4">
                Device Allocations
              </Typography>
              <Box mt={1} className="controller-status-card">
                <Box className="cont-card">
                  <Typography className="total-assig-contoller" component="h4" mr={1}>
                    Total assigned devices
                  </Typography>
                  <Typography className="count" component="h2">
                    {assignedCount}
                  </Typography>
                </Box>
                <Box className="cont-card" mt={3}>
                  <Typography className="total-assig-contoller" component="h4" mr={1}>
                    Total unassigned devices
                  </Typography>
                  <Typography className="count" component="h2">
                    {unassignedCount}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
