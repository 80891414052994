import React, { useEffect, useState, useCallback } from "react";
import "../MyPatients/MyPatients.css";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import AddUser from "./Add user/AddUser";
import { GetApiParam } from "../../Api/Api";
import UserTable from "./components/UserTable";
import { Toaster } from "react-hot-toast";
import { USER_ROUTE } from "../../Api/Routes";
import { getCurrentUserId } from "../../utils/helpers";

const User = () => {
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const response = await GetApiParam(USER_ROUTE, {
        role: "Admin",
      });

      setUserData(response?.data?.users || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userData) {
      fetchUserData();
    }
  }, [userData]);

  const AddUpdateUser = useCallback(() => {
    fetchUserData();
  }, []);

  const refreshLocalUser = useCallback(() => {
    if (userData) {
      const userID = getCurrentUserId();
      const user = userData.find((user) => user.id === userID);

      const currentUser = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        role: user?.role,
        id: user?.id,
        title: user?.title,
      };
      localStorage.setItem("user", JSON.stringify(currentUser));
    }
  }, [userData]);

  useEffect(() => {
    refreshLocalUser();
  }, [AddUpdateUser, refreshLocalUser]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px" }} />;
  }

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Typography className="title" component="h4">
            Admins
          </Typography>
        </Box>
        <Box className="right btn-font-small">
          <Stack direction="row" gap={2}>
            <ButtonComponent
              buttonText="Add user"
              buttonVariant="contained"
              buttonStyle={{ borderRadius: "100px" }}
              startIcon={<AddIcon />}
              onClick={handleOpen}
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ mt: "30px" }}>
        <UserTable userData={userData || []} AddUpdateUser={AddUpdateUser} />
      </Box>
      <AddUser
        open={open}
        handleClose={handleClose}
        AddUpdateUser={AddUpdateUser}
      />
    </Box>
  );
};

export default User;
