import React, { useEffect, useState } from "react";
import "../MyPatients/MyPatients.css";
import "../../Assets/Css/Responsive.css";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import AddControllers from "./Add controllers/AddControllers";
import { GetApi } from "../../Api/Api";
import ControllerTable from "./components/ControllerTable";
import { Toaster } from "react-hot-toast";
import { DEVICE_ROUTE } from "../../Api/Routes";

const Controllers = () => {
  const [open, setOpen] = useState(false);
  const [controllerData, setControllerData] = useState(null);
  const [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.role === "Admin";

  const fetchControllerData = async () => {
    try {
      const response = await GetApi(DEVICE_ROUTE);
      setControllerData(response?.data?.devices);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!controllerData) {
      fetchControllerData();
    }
  }, [controllerData]);

  const AddUpdateController = () => {
    fetchControllerData();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px" }} />;
  }

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Typography className="title" component="h4">
            Devices
          </Typography>
        </Box>
        {isAdmin && (
          <Box className="right btn-font-small">
            <Stack direction="row" gap={2}>
              <ButtonComponent
                buttonText="Add Device"
                buttonVariant="contained"
                buttonStyle={{ borderRadius: "100px" }}
                startIcon={<AddIcon />}
                onClick={handleOpen}
              />
            </Stack>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: "30px" }}>
        <ControllerTable
          controllerData={controllerData || []}
          AddUpdateController={AddUpdateController}
        />
      </Box>
      <AddControllers
        open={open}
        handleClose={handleClose}
        AddUpdateController={AddUpdateController}
      />
    </Box>
  );
};

export default Controllers;
