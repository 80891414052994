import { Box } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import {
  EVENT_LEFT_PADDING,
  EVENT_RIGHT_PADDING,
  GOAL_SQUARE_SIZE,
  EVENT_COLORS,
  EVENT_HEIGHT,
  Y_PADDING_TOP,
  Y_PADDING_BOTTOM,
  GOAL_LABEL_OFFSET_X,
  GOAL_LABEL_OFFSET_Y,
  EVENT_TOOLTIP_PADDING,
} from "./constants";

const EventChart = ({ data }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const { label, goal, xs, ys, start_treatment, end_treatment } = data;

  const mousePosition = useRef({ x: 0, y: 0 })
  const draw = useCallback(
    (ctx) => {
      if (!canvasRef.current || !containerRef.current) return;

      canvasRef.current.width = containerRef.current.clientWidth;
      canvasRef.current.height = EVENT_HEIGHT + Y_PADDING_TOP + Y_PADDING_BOTTOM;
      ctx.font = '14px "Arial"';

      // Determine the maximum y value dynamically
      let maxYValue = Math.ceil(Math.max(...ys, goal));
      let yAxisStep =
        maxYValue <= 1 ? maxYValue / 6 : Math.ceil(maxYValue / 6);

      // avoid division by zero case
      if (maxYValue === 0) maxYValue = 1;
      if (yAxisStep === 0) yAxisStep = 1;
      
      // Set background color
      ctx.fillStyle = EVENT_COLORS.BG2;
      ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      
      // Draw X-Axis Line
      ctx.strokeStyle = EVENT_COLORS.Accent3;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(EVENT_LEFT_PADDING, EVENT_HEIGHT + Y_PADDING_TOP);
      ctx.lineTo(canvasRef.current.width - EVENT_RIGHT_PADDING, EVENT_HEIGHT + Y_PADDING_TOP);
      ctx.stroke();

      // Draw Y-Axis Line
      ctx.beginPath();
      ctx.moveTo(EVENT_LEFT_PADDING, Y_PADDING_TOP);
      ctx.lineTo(EVENT_LEFT_PADDING, EVENT_HEIGHT + Y_PADDING_TOP);
      ctx.stroke();

      // Draw goal line & label
      if (goal !== undefined && goal !== null) {
        const GOAL_LINE_Y =
          Y_PADDING_TOP + (EVENT_HEIGHT - (goal / maxYValue) * EVENT_HEIGHT);
        ctx.strokeStyle = "#74FF8B";
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(EVENT_LEFT_PADDING, GOAL_LINE_Y);
        ctx.lineTo(canvasRef.current.width - EVENT_RIGHT_PADDING, GOAL_LINE_Y);
        ctx.stroke();

        const goalLabel = `Goal ${goal}`;

        ctx.fillStyle = "#74FF8B";
        const textMetrics = ctx.measureText(goalLabel);
          ctx.fillText(
            goalLabel,
            canvasRef.current.width - EVENT_RIGHT_PADDING - textMetrics.width - GOAL_LABEL_OFFSET_X,
            GOAL_LINE_Y - GOAL_LABEL_OFFSET_Y,
          );
      }

      // Compute the x step spacing
      let stepX = 0;
      if (xs.length > 1) {
        stepX = (canvasRef.current.width - EVENT_LEFT_PADDING - EVENT_RIGHT_PADDING) / (xs.length - 1);
      }

      //TODO: Layout baseline/treatment/follow-up labels based on where these lines go
      if(start_treatment) {
        const xStart = EVENT_LEFT_PADDING + start_treatment * stepX;

        const maxLabelWidth = (xStart - EVENT_LEFT_PADDING);
        ctx.fillStyle = EVENT_COLORS.Accent3;
        ctx.textAlign = "center";
        ctx.font = "24px Maitree";
        ctx.fillText("Baseline", EVENT_LEFT_PADDING + (maxLabelWidth / 2), Y_PADDING_TOP, maxLabelWidth);
        ctx.textAlign = "start";

        ctx.strokeStyle = "#00ff00";

        ctx.beginPath();
        ctx.setLineDash([5, 3]);
        ctx.moveTo(xStart, Y_PADDING_TOP);
        ctx.lineTo(xStart, EVENT_HEIGHT + Y_PADDING_TOP);
        ctx.stroke();
        ctx.setLineDash([]);
      }

      if(end_treatment) {
        const xEnd = EVENT_LEFT_PADDING + end_treatment * stepX;

        let xStart;
        if(start_treatment) {
          xStart = EVENT_LEFT_PADDING + start_treatment * stepX;
        } else {
          xStart = EVENT_LEFT_PADDING;
        }

        const maxLabelWidth = (xEnd - xStart);
        ctx.fillStyle = EVENT_COLORS.Accent3;
        ctx.textAlign = "center";
        ctx.font = "24px Maitree";
        ctx.fillText("Treatment", xStart + (maxLabelWidth / 2), Y_PADDING_TOP, maxLabelWidth);
        ctx.textAlign = "start";

        ctx.strokeStyle = "#ff0000";

        ctx.beginPath();
        ctx.setLineDash([5, 3]);
        ctx.moveTo(xEnd, Y_PADDING_TOP);
        ctx.lineTo(xEnd, EVENT_HEIGHT + Y_PADDING_TOP);
        ctx.stroke();
        ctx.setLineDash([]);

        if(end_treatment !== xs.length - 1) {
          const followupStart = xEnd;
          const followupEnd = EVENT_LEFT_PADDING + (xs.length - 1) * stepX;

          const followupWidth = followupEnd - followupStart;
          ctx.textAlign = "center";
          ctx.fillText("Follow-up", followupStart + (followupWidth / 2), Y_PADDING_TOP, followupWidth);
          ctx.textAlign = "start";
        }
      }

      // Draw x-axis labels and ticks
      ctx.font = "16px Maitree";
      ctx.fillStyle = EVENT_COLORS.Accent3;
      ctx.textAlign = "center";
      ctx.strokeStyle = EVENT_COLORS.Accent3;
      xs.forEach((date, index) => {
        const x = EVENT_LEFT_PADDING + index * stepX;
        // X-Axis Ticks
        ctx.beginPath();
        ctx.moveTo(x, EVENT_HEIGHT + Y_PADDING_TOP);
        ctx.lineTo(x, EVENT_HEIGHT + Y_PADDING_TOP + 10); // Draw a small tick
        ctx.stroke();

        ctx.fillText(date, x, EVENT_HEIGHT + Y_PADDING_TOP + 25);
      });

      // Draw y-axis values dynamically (based on maxYValue) and ticks
      ctx.font = "16px Maitree";
      ctx.fillStyle = EVENT_COLORS.Accent3;
      ctx.textAlign = "right";
      for (let i = 0; i <= maxYValue; i += yAxisStep) {
        const y =
          Y_PADDING_TOP + (EVENT_HEIGHT - (i / maxYValue) * EVENT_HEIGHT);
        // Y-Axis Ticks
        ctx.beginPath();
        ctx.moveTo(EVENT_LEFT_PADDING - 10, y); // A small tick
        ctx.lineTo(EVENT_LEFT_PADDING, y);
        ctx.stroke();

        // Y-Axis Values
        const label = formatter.format(i);
        ctx.fillText(label, EVENT_LEFT_PADDING - 15, y + 5);
      }

      // Draw vertical label for "Sleep Duration (Hrs)"
      ctx.save();
      ctx.translate(20, EVENT_HEIGHT / 2 + Y_PADDING_TOP);
      ctx.rotate(-Math.PI / 2);
      ctx.textAlign = "center";
      ctx.fillText(label, 0, 0);
      ctx.restore();

      // Plot lines
      ctx.strokeStyle = EVENT_COLORS.Accent3;
      ctx.lineWidth = 2;
      ctx.beginPath();
      for (let i = 0; i < xs.length; i++) {
        const x = EVENT_LEFT_PADDING + i * stepX; // Calculate x for each point
        const y =
          Y_PADDING_TOP + (EVENT_HEIGHT - (ys[i] / maxYValue) * EVENT_HEIGHT);

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();

      // Plot data points
      let hoveredVal = undefined;
      for (let i = 0; i < xs.length; i++) {
        const x = EVENT_LEFT_PADDING + i * stepX; // Calculate x for each point
        const y =
          Y_PADDING_TOP + (EVENT_HEIGHT - (ys[i] / maxYValue) * EVENT_HEIGHT);

        // Check if mouse is near
        const hovered = Math.max(
          Math.abs(mousePosition.current.x - x),
          Math.abs(mousePosition.current.y - y),
        ) <= (0.5*GOAL_SQUARE_SIZE);
        if (hovered) {
          hoveredVal = i;
        }

        // Draw squares on each data point
        ctx.fillStyle = hovered ? "#D5F1E4" : EVENT_COLORS.Accent3;
        ctx.fillRect(
          x - GOAL_SQUARE_SIZE / 2,
          y - GOAL_SQUARE_SIZE / 2,
          GOAL_SQUARE_SIZE,
          GOAL_SQUARE_SIZE
        );
      }

      // Draw tooltip
      if (hoveredVal !== undefined) {
        ctx.font = "16px Maitree";
        ctx.textAlign = "left";

        const tooltipText = `${xs[hoveredVal]}: ${ys[hoveredVal]}`
        const textMetrics = ctx.measureText(tooltipText);
        const tooltipWidth = textMetrics.width + 2 * EVENT_TOOLTIP_PADDING;
        const tooltipHeight =
          textMetrics.actualBoundingBoxAscent +
          textMetrics.actualBoundingBoxDescent +
          2 * EVENT_TOOLTIP_PADDING;

        ctx.fillStyle = EVENT_COLORS.BG2;
        ctx.fillRect(
          mousePosition.current.x - tooltipWidth,
          mousePosition.current.y - tooltipHeight,
          tooltipWidth,
          tooltipHeight
        );

        ctx.strokeStyle = EVENT_COLORS.Accent3;
        ctx.strokeRect(
          mousePosition.current.x - tooltipWidth,
          mousePosition.current.y - tooltipHeight,
          tooltipWidth,
          tooltipHeight
        );

        ctx.fillStyle = EVENT_COLORS.Accent3;
        ctx.fillText(
          tooltipText,
          mousePosition.current.x + EVENT_TOOLTIP_PADDING - tooltipWidth,
          mousePosition.current.y -
            tooltipHeight +
            textMetrics.actualBoundingBoxAscent +
            EVENT_TOOLTIP_PADDING
        );
      }
    },
    [xs, ys, label, goal, end_treatment, start_treatment]
  );

  useEffect(() => {
    if (!xs || !ys) return;
    
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const handleResize = () => draw(context);
    
    const handleMouseMove = (event) => {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      mousePosition.current.x = event.clientX - rect.left;
      mousePosition.current.y = event.clientY - rect.top;
      draw(context);
    };
    
    canvas.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", handleResize);
    handleResize();
    
    return () => {
      window.removeEventListener("resize", handleResize);
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [draw, xs, ys]);

  if (!xs || !ys) {
    return null;
  }

  return (
    <Box px={3} display="flex" alignItems="center" flex={1} position="relative">
      <Box
        mb="20px"
        ref={containerRef}
        style={{ width: "100%" }}
      >
        <canvas ref={canvasRef} />
      </Box>
    </Box>
  );
};

export default EventChart;

const formatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
