export const heightOptions = [
  "20",
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100",
];
export const weightOptions = [
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "110",
  "120",
  "130",
  "140",
  "150",
  "160",
  "170",
  "180",
  "190",
  "200",
  "210",
  "220",
  "230",
  "240",
  "250",
];
export const genderOptions = ["Male", "Female", "Other"];