import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, Snackbar } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useCallback, useEffect, useState } from "react";
import { parseDate } from "../../../../utils/helpers";
import { getPatientEvents } from "../../../../Api/AdapterApi";
import EventPill from "./EventPill";
import EventModal from "./EventModal";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { postApi } from "../../../../Api/Api";

const MAX_PINNED_EVENTS = 6;

const EventsCard = ({ patientId, pinnedEventsData }) => {
  const [day, setDay] = useState(null);
  const [allEvents, setAllEvents] = useState(null);
  const [pinnedEvents, setPinnedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [openPinError, setOpenPinError] = useState(false);

  useEffect(() => {
    if (pinnedEventsData) {
      setPinnedEvents(pinnedEventsData.pinned);
    }
  }, [pinnedEventsData]);

  const fetchEventsData = useCallback(async () => {
    if (!patientId || !day) return;

    try {
      const params = {
        patient_id: patientId,
        day,
      };
      const response = await getPatientEvents(params);

      if (response?.events) {
        setAllEvents(response.events.map(event => ({
          ...event,
          pinned: pinnedEvents.includes(event.name),
        })));
      }
    } catch (error) {
      console.error("Error fetching events data:", error);
    }
  }, [patientId, day]);

  useEffect(() => {
    if (!day) return;
    fetchEventsData();
  }, [fetchEventsData, day]);

  const handleDayChange = (date) => {
    if (!date) return;
    const formattedDate = parseDate(date);
    setDay(formattedDate);
  };

  const handleOnClickEvent = (event) => {
    if (event?.graphs.length === 0) return;

    setSelectedEvent(event);
  };

  const updatePinnedEvents = async (event) => {
    const isPinned = pinnedEvents.includes(event);
    const newPinnedEvents = isPinned
      ? pinnedEvents.filter(e => e !== event)
      : [...pinnedEvents, event];
    if (newPinnedEvents.length > MAX_PINNED_EVENTS) {
      setOpenPinError(true);
      return false;
    }
    try {
      await postApi(`/patient/${patientId}/pinned_events`, { pinned: newPinnedEvents });
      setPinnedEvents(newPinnedEvents);
    } catch (error) {
      console.log("Error updating pinned events:", error);
    }
    return true;
  };

  return (
    <>
      <Box id="event-container">
        <Box className="my-patients-topbar" mt={3}>
          <Typography className="title" component="h4">
            Events
          </Typography>
          <Box display="flex" gap="20px">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box className="select-option">
                <DesktopDatePicker
                  disableFuture
                  format="MM-dd-yy"
                  onAccept={handleDayChange}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>

        {(!allEvents || allEvents.length === 0) && (
          <Box
            display="flex"
            alignItems="center"
            id="timeline-container"
            height="100%"
            pt={2}
            bgcolor="#042835"
            justifyContent="center"
            p={5}
          >
            <Typography component="h4" className="text-primery" align="center">
              {!day ? "Select a day" : "No data"}
            </Typography>
          </Box>
        )}
        {allEvents && allEvents.length > 0 && (
          <Box>
            <Box sx={wrapperStyle}>
              {allEvents.filter((event) => event.pinned).map((event, index) => {
                const hasGraph = event?.graphs && event.graphs.length > 0;

                return (
                  <Box
                    key={index}
                    onClick={() => handleOnClickEvent(event)}
                    sx={{
                      ...pillWrapperStyle,
                      cursor: hasGraph ? "pointer" : "initial",
                      "&:hover": {
                        border: hasGraph
                          ? "3px solid #91D9B9"
                          : "1px solid #91D9B9",
                      },
                    }}
                  >
                    <EventPill event={event} />
                  </Box>
                );
              })}
            </Box>
            <Accordion defaultExpanded={pinnedEvents.length === 0}>
              <AccordionSummary sx={wrapperStyle} expandIcon={<ExpandMoreIcon sx={{ color: '#91D9B9' }} />}>
                <Typography sx={{ color: "#91D9B9", width: '100%', textAlign: 'center' }}
                >
                  View All Events
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={wrapperStyle}>
                {allEvents.map((event, index) => {
                  const hasGraph = event?.graphs && event.graphs.length > 0;

                  return (
                    <Box
                      key={index}
                      onClick={() => handleOnClickEvent(event)}
                      sx={{
                        ...pillWrapperStyle,
                        cursor: hasGraph ? "pointer" : "initial",
                        "&:hover": {
                          border: hasGraph
                            ? "3px solid #91D9B9"
                            : "1px solid #91D9B9",
                        },
                      }}
                    >
                      <EventPill event={event} />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </Box>
      <EventModal
        open={!!selectedEvent}
        onClose={() => setSelectedEvent(false)}
        event={selectedEvent}
        patientId={patientId}
        updatePinnedEvents={updatePinnedEvents}
      />
      <Snackbar
        open={openPinError}
        autoHideDuration={3000}
        message="You can only pin up to 6 events!"
        onClose={() => setOpenPinError(false)}
        severity="error"
      />
    </>
  );
};

const wrapperStyle = {
  display: "flex",
  flexWrap: "wrap",
  backgroundColor: "#042835",
};

export default EventsCard;

const pillWrapperStyle = {
  flex: "1 0 21%",
  margin: "16px",
  height: "74px",
  border: "1px solid #91D9B9",
  borderRadius: "60px",
  display: "flex",
  alignItems: "center",
  px: "12px",
  justifyContent: "space-between",
  color: "#91D9B9",
};
