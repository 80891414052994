import { Box } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  EVENT_LINE_PADDING,
  TIMELINE_TOP_PADDING,
  TIMELINE_BOTTOM_PADDING,
  TIMELINE_SIDE_PADDING,
  BASELINE_HEIGHT,
  EVENT_LINE_HEIGHT,
  TIMELIME_AXIS_THICKNESS,
  TIMELINE_TICK_THICKNESS,
  TIMELINE_TICK_HEIGHT,
  TIMELINE_TICK_LABEL_SPACING,
  TIMELINE_EVENT_OUTLINE_THICKNESS,
  TIMELINE_TOOLTIP_OUTLINE_THICKNESS,
  TIMELINE_TOOLTIP_PADDING,
  TIMELINE_TIME_OFFSET_X,
  TIMELINE_TIME_OFFSET_Y,
  TIMELINE_MIN_X,
  TIMELINE_COLORS,
} from "./constants";

const TimelineChart = ({ data }) => {
  const events = data?.events;
  const timeline_start_minute = data?.start_minute;
  const timeline_minutes = data?.minutes;
  const max_row = data?.max_row;

  const canvasRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!events) return;

    const handleMouseMove = (event) => {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      setMousePosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    };

    const canvas = canvasRef.current;
    canvas.addEventListener("mousemove", handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [events]);

  const draw = useCallback(
    (ctx) => {
      canvasRef.current.width =
        document.getElementById("timeline-container").clientWidth;

      // --- Timeline initialization code ---
      const TIMELINE_HEIGHT =
        TIMELINE_BOTTOM_PADDING +
        EVENT_LINE_PADDING +
        BASELINE_HEIGHT +
        (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * max_row +
        TIMELINE_TOP_PADDING;

      canvasRef.current.height = TIMELINE_HEIGHT;

      ctx.font = '14px "Arial"';
      const TIMELINE_WIDTH = canvasRef.current.width - 2 * TIMELINE_MIN_X;

      const timelineMouseX = mousePosition.x;
      const timelineMouseY = mousePosition.y;

      ctx.fillStyle = TIMELINE_COLORS.BG2;
      ctx.fillRect(0, 0, TIMELINE_WIDTH, TIMELINE_HEIGHT);

      const TIMELINE_AXIS_Y = TIMELINE_HEIGHT - TIMELINE_BOTTOM_PADDING;
      const BASELINE_Y = TIMELINE_AXIS_Y - EVENT_LINE_PADDING - BASELINE_HEIGHT;
      const TIMELINE_AXIS_WIDTH = TIMELINE_WIDTH - 2 * TIMELINE_SIDE_PADDING;

      ctx.fillStyle = TIMELINE_COLORS.Accent3;
      ctx.fillRect(
        TIMELINE_SIDE_PADDING,
        TIMELINE_AXIS_Y - 0.5 * TIMELIME_AXIS_THICKNESS,
        TIMELINE_AXIS_WIDTH,
        TIMELIME_AXIS_THICKNESS
      );

      const timeline_start_hour = Math.ceil(timeline_start_minute / 60);
      const timeline_hour_offset = (60 - (timeline_start_minute % 60)) % 60;
      const timeline_hours = Math.floor((timeline_minutes - timeline_hour_offset) / 60);
      for (let i = 0; i <= timeline_hours; i++) {
        const hour_frac = (timeline_hour_offset + 60*i) / timeline_minutes;
        
        const x =
          hour_frac * TIMELINE_AXIS_WIDTH -
          0.5 * TIMELINE_TICK_THICKNESS +
          TIMELINE_SIDE_PADDING;
        ctx.fillRect(
          x,
          TIMELINE_AXIS_Y,
          TIMELINE_TICK_THICKNESS,
          TIMELINE_TICK_HEIGHT
        );

        const hour = (i + timeline_start_hour) % 24;
        let labelText = "";
        if (hour === 0) {
          labelText = `12 AM`;
        } else if (hour > 12) {
          labelText = `${(hour - 12).toString().padStart(2, "0")} PM`;
        } else {
          labelText = `${hour.toString().padStart(2, "0")} AM`;
        }

        const textMetrics = ctx.measureText(labelText);
        ctx.fillText(
          labelText,
          x - 0.5 * textMetrics.width,
          TIMELINE_AXIS_Y +
            TIMELINE_TICK_HEIGHT +
            textMetrics.actualBoundingBoxAscent +
            TIMELINE_TICK_LABEL_SPACING
        );
      }

      // // The timeline doesn't cover an hour 
      // if (timeline_hours === 0) {
      //   const mouseTime =
      //     (timeline_minutes * (timelineMouseX - TIMELINE_MIN_MOUSE_X)) /
      //       TIMELINE_AXIS_WIDTH +
      //     timeline_start_minute;
      //   const hour = Math.floor(mouseTime / 60) % 24;
      //   const min = (Math.floor(mouseTime) % 60)
      //     .toString()
      //     .padStart(2, "0");
      //   let mouseTimeLabel = "";
      //   if (hour === 0) {
      //     mouseTimeLabel = `12:${min} AM`;
      //   } else if (hour > 12) {
      //     mouseTimeLabel = `${(hour - 12)
      //       .toString()
      //       .padStart(2, "0")}:${min} PM`;
      //   } else {
      //     mouseTimeLabel = `${hour.toString().padStart(2, "0")}:${min} AM`;
      //   }
      // }

      for (let i = 0; i < events.length; i++) {
        const event = events[i];

        const minY =
          BASELINE_Y - (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * event.row;
        const maxY =
          minY + (event.row === 0 ? BASELINE_HEIGHT : EVENT_LINE_HEIGHT);

        const minX =
          TIMELINE_SIDE_PADDING +
          event.start * (TIMELINE_AXIS_WIDTH / timeline_minutes);
        const maxX =
          TIMELINE_SIDE_PADDING +
          event.end * (TIMELINE_AXIS_WIDTH / timeline_minutes);

        if (
          minX < timelineMouseX &&
          timelineMouseX < maxX &&
          minY < timelineMouseY &&
          timelineMouseY < maxY
        ) {
          ctx.fillStyle = TIMELINE_COLORS.Accent2;
          ctx.fillRect(minX, minY, maxX - minX, maxY - minY);

          ctx.fillStyle = event.color;
          ctx.fillRect(
            minX + TIMELINE_EVENT_OUTLINE_THICKNESS,
            minY + TIMELINE_EVENT_OUTLINE_THICKNESS,
            maxX - minX - 2 * TIMELINE_EVENT_OUTLINE_THICKNESS,
            maxY - minY - 2 * TIMELINE_EVENT_OUTLINE_THICKNESS
          );
        } else {
          ctx.fillStyle = event.color;
          ctx.fillRect(minX, minY, maxX - minX, maxY - minY);
        }
      }

      const TIMELINE_MIN_MOUSE_X = TIMELINE_SIDE_PADDING;
      const TIMELINE_MAX_MOUSE_X = TIMELINE_WIDTH - TIMELINE_SIDE_PADDING;
      if (
        TIMELINE_MIN_MOUSE_X <= timelineMouseX &&
        timelineMouseX <= TIMELINE_MAX_MOUSE_X &&
        0 <= timelineMouseY &&
        timelineMouseY <= TIMELINE_HEIGHT
      ) {
        ctx.fillStyle = TIMELINE_COLORS.Accent2;
        ctx.fillRect(
          timelineMouseX - 0.5 * TIMELIME_AXIS_THICKNESS,
          0,
          TIMELIME_AXIS_THICKNESS,
          TIMELINE_AXIS_Y
        );

        const mouseTime =
          (timeline_minutes * (timelineMouseX - TIMELINE_MIN_MOUSE_X)) /
            TIMELINE_AXIS_WIDTH +
          timeline_start_minute;
        const hour = Math.floor(mouseTime / 60) % 24;
        const min = (Math.floor(mouseTime) % 60)
          .toString()
          .padStart(2, "0");
        let mouseTimeLabel = "";
        if (hour === 0) {
          mouseTimeLabel = `12:${min} AM`;
        } else if (hour > 12) {
          mouseTimeLabel = `${(hour - 12)
            .toString()
            .padStart(2, "0")}:${min} PM`;
        } else {
          mouseTimeLabel = `${hour.toString().padStart(2, "0")}:${min} AM`;
        }

        const textMetrics = ctx.measureText(mouseTimeLabel);

        ctx.fillStyle = TIMELINE_COLORS.Accent3;
        if (
          timelineMouseX + 2 * TIMELINE_TIME_OFFSET_X + textMetrics.width >
          TIMELINE_WIDTH
        ) {
          ctx.fillText(
            mouseTimeLabel,
            timelineMouseX - textMetrics.width - TIMELINE_TIME_OFFSET_X,
            TIMELINE_TIME_OFFSET_Y
          );
        } else {
          ctx.fillText(
            mouseTimeLabel,
            timelineMouseX + TIMELINE_TIME_OFFSET_X,
            TIMELINE_TIME_OFFSET_Y
          );
        }
      }

      ctx.setLineDash([]);
      ctx.lineWidth = TIMELINE_TOOLTIP_OUTLINE_THICKNESS;
      for (let i = 0; i < events.length; i++) {
        const event = events[i];

        const minY =
          BASELINE_Y - (EVENT_LINE_PADDING + EVENT_LINE_HEIGHT) * event.row;
        const maxY =
          minY + (event.row === 0 ? BASELINE_HEIGHT : EVENT_LINE_HEIGHT);

        const minX =
          TIMELINE_SIDE_PADDING +
          event.start * (TIMELINE_AXIS_WIDTH / timeline_minutes);
        const maxX =
          TIMELINE_SIDE_PADDING +
          event.end * (TIMELINE_AXIS_WIDTH / timeline_minutes);

        if (
          minX < timelineMouseX &&
          timelineMouseX < maxX &&
          minY < timelineMouseY &&
          timelineMouseY < maxY
        ) {
          const eventText = `${event.text} (${(60*(event.end - event.start)).toFixed(2)} s)`
          const textMetrics = ctx.measureText(eventText);
          const tooltipWidth = textMetrics.width + 2 * TIMELINE_TOOLTIP_PADDING;
          const tooltipHeight =
            textMetrics.actualBoundingBoxAscent +
            textMetrics.actualBoundingBoxDescent +
            2 * TIMELINE_TOOLTIP_PADDING;

          if (timelineMouseX + tooltipWidth > TIMELINE_WIDTH) {
            ctx.fillStyle = TIMELINE_COLORS.BG2;
            ctx.fillRect(
              timelineMouseX - tooltipWidth,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.strokeStyle = TIMELINE_COLORS.Accent3;
            ctx.strokeRect(
              timelineMouseX - tooltipWidth,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.fillStyle = TIMELINE_COLORS.Accent3;
            ctx.fillText(
              eventText,
              timelineMouseX + TIMELINE_TOOLTIP_PADDING - tooltipWidth,
              timelineMouseY -
                tooltipHeight +
                textMetrics.actualBoundingBoxAscent +
                TIMELINE_TOOLTIP_PADDING
            );
          } else {
            ctx.fillStyle = TIMELINE_COLORS.BG2;
            ctx.fillRect(
              timelineMouseX,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.strokeStyle = TIMELINE_COLORS.Accent3;
            ctx.strokeRect(
              timelineMouseX,
              timelineMouseY - tooltipHeight,
              tooltipWidth,
              tooltipHeight
            );

            ctx.fillStyle = TIMELINE_COLORS.Accent3;
            ctx.fillText(
              eventText,
              timelineMouseX + TIMELINE_TOOLTIP_PADDING,
              timelineMouseY -
                tooltipHeight +
                textMetrics.actualBoundingBoxAscent +
                TIMELINE_TOOLTIP_PADDING
            );
          }
        }
      }
    },
    [
      mousePosition.x,
      mousePosition.y,
      events,
      timeline_minutes,
      timeline_start_minute,
      max_row,
    ]
  );

  useEffect(() => {
    if (!events) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const handleResize = () => {
      // canvas.width = window.innerWidth;
      // canvas.height = window.innerHeight;
      draw(context);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [draw, events]);

  return (
    <Box
      display="flex"
      alignItems="center"
      id="timeline-container"
      height="100%"
      pt={2}
    >
      <canvas ref={canvasRef} />
    </Box>
  );
};

export default TimelineChart;
