import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "./SideBar.css";
import "../../Assets/Css/Responsive.css";
import logo from "./../../Assets/Images/Login/logo.svg";
import { ReactComponent as DashboardIcon } from "./../../Assets/Images/Sidebar/dashboard-icon.svg";
import { ReactComponent as MyPatientsIcon } from "./../../Assets/Images/Sidebar/my-patients-icon.svg";
import { ReactComponent as GoalSetupIcon } from "./../../Assets/Images/Sidebar/goals-setup-icon.svg";
import { ReactComponent as SetupMonitoringIcon } from "./../../Assets/Images/Sidebar/setup-monitoring-icon.svg";
import { ReactComponent as EventMonitoringIcon } from "./../../Assets/Images/Sidebar/event-monitoring-icon.svg";
import { basePath } from "../../routes/Config";

const SideBar = () => {
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.role === "Admin";

  return (
    <Box className="sidebar-container">
      <Box className="logo-container">
        <img src={logo} alt="" />
      </Box>
      <Box className="sidebar-menu-container">
        <List>
          {user &&
            isAdmin &&
            adminMenuItems.map((menuItem, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding className="menu-div">
                  <ListItemButton
                    component={Link}
                    to={menuItem.path}
                    className={
                      location.pathname === menuItem.path ? "active" : ""
                    }
                  >
                    <ListItemIcon
                      className={
                        "sidebar-icon-box " +
                        (menuItem.path === basePath ? "svg-stroke" : "")
                      }
                    >
                      <menuItem.icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={menuItem.primary}
                      className="menu-name"
                    />
                  </ListItemButton>
                </ListItem>
                <Divider className="divider-color" />
              </React.Fragment>
            ))}
          {user &&
            !isAdmin &&
            physicianMenuItems.map((menuItem, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding className="menu-div">
                  <ListItemButton
                    component={Link}
                    to={menuItem.path}
                    className={
                      location.pathname === menuItem.path ? "active" : ""
                    }
                  >
                    <ListItemIcon
                      className={
                        "sidebar-icon-box " +
                        (menuItem.path === basePath ? "svg-stroke" : "")
                      }
                    >
                      <menuItem.icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={menuItem.primary}
                      className="menu-name"
                    />
                  </ListItemButton>
                </ListItem>
                <Divider className="divider-color" />
              </React.Fragment>
            ))}
        </List>
      </Box>
    </Box>
  );
};

export default SideBar;

const adminMenuItems = [
  { icon: DashboardIcon, primary: "Dashboard", path: basePath },
  {
    icon: MyPatientsIcon,
    primary: "Patients",
    path: `${basePath}patients`,
  },
  {
    icon: GoalSetupIcon,
    primary: "Hospitals",
    path: `${basePath}hospitals`,
  },
  {
    icon: SetupMonitoringIcon,
    primary: "Physicians",
    path: `${basePath}physicians`,
  },
  {
    icon: EventMonitoringIcon,
    primary: "Admins",
    path: `${basePath}admins`,
  },
  {
    icon: EventMonitoringIcon,
    primary: "Devices",
    path: `${basePath}devices`,
  },
];

const physicianMenuItems = [
  { icon: DashboardIcon, primary: "Dashboard", path: basePath },
  {
    icon: MyPatientsIcon,
    primary: "Patients",
    path: `${basePath}patients`,
  },
  // { icon: EventMonitoringIcon, primary: "View Data", path: `${basePath}graph-monitoring`},
  {
    icon: EventMonitoringIcon,
    primary: "Devices",
    path: `${basePath}devices`,
  },
  // { icon: EventMonitoringIcon, primary: "View Data", path: `${basePath}sensors-monitoring` },
];
