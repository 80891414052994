import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  PROGRESS_TOP_PADDING,
  PROGRESS_BOTTOM_PADDING,
  PROGRESS_SIDE_PADDING,
  GOAL_LINE_SPACING,
  GOAL_SQUARE_SIZE,
  GOAL_SQUARE_OUTLINE,
  GOAL_SQUARE_SPACING,
  GOAL_DIVIDER_SPACING,
  GOAL_DIVIDER_THICKNESS,
  GOAL_LINE_THICKNESS,
  GOAL_TICK_THICKNESS,
  GOAL_TICK_HEIGHT,
  GOAL_TICK_LABEL_SPACING,
  GOAL_TICK_INTERVAL,
  PROGRESS_COLORS,
} from "./constants";

const ProgressChart = ({ goals, goal_data }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!goals || !goal_data) return;

    const handleMouseMove = (event) => {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      setMousePosition({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    };

    const canvas = canvasRef.current;
    canvas.addEventListener("mousemove", handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
    };
  }, [goals, goal_data]);

  const draw = useCallback(
    (ctx) => {
      ctx.font = '14px "Acumin Pro"';
      let maxLabelTextWidth = 0;
      goal_data.forEach((dataPoint) => {
        const textMetrics = ctx.measureText(dataPoint.label);
        if (textMetrics.width > maxLabelTextWidth)
          maxLabelTextWidth = textMetrics.width;
      });

      const contentWidth =
        goal_data.length * (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING) +
        GOAL_SQUARE_SPACING +
        PROGRESS_SIDE_PADDING * 2 +
        maxLabelTextWidth +
        150; // Adding extra padding to account for spacing

      // Set canvas width to accommodate the full width of content
      canvasRef.current.width = contentWidth;

      const PROGRESS_HEIGHT =
        PROGRESS_BOTTOM_PADDING +
        goals.length * GOAL_LINE_SPACING +
        PROGRESS_TOP_PADDING;
      canvasRef.current.height = PROGRESS_HEIGHT;

      // --- Progress graph initialization code ---
      ctx.font = '14px "Acumin Pro"';
      let maxGoalWidth = 0;
      for (let i = 0; i < goals.length; i++) {
        const textMetrics = ctx.measureText(goals[i]);
        if (textMetrics.width > maxGoalWidth) maxGoalWidth = textMetrics.width;
      }

      const PROGRESS_WIDTH = contentWidth - 2;
      const goalDividerX =
        maxGoalWidth + PROGRESS_SIDE_PADDING + GOAL_DIVIDER_SPACING;
      const goalDividerMinY = PROGRESS_TOP_PADDING;
      const goalDividerHeight =
        PROGRESS_HEIGHT - PROGRESS_TOP_PADDING - PROGRESS_BOTTOM_PADDING;

      ctx.fillStyle = PROGRESS_COLORS.BG2;
      ctx.fillRect(0, 0, PROGRESS_WIDTH, PROGRESS_HEIGHT);

      ctx.fillStyle = PROGRESS_COLORS.Accent3;
      ctx.fillRect(
        goalDividerX - 0.5 * GOAL_DIVIDER_THICKNESS,
        goalDividerMinY,
        GOAL_DIVIDER_THICKNESS,
        goalDividerHeight
      );

      ctx.fillRect(
        goalDividerX,
        goalDividerMinY + goalDividerHeight - 0.5 * GOAL_DIVIDER_THICKNESS,
        PROGRESS_WIDTH - goalDividerX - PROGRESS_SIDE_PADDING,
        GOAL_DIVIDER_THICKNESS
      );

      for (let i = 0; i < goals.length; i++) {
        const lineY = PROGRESS_TOP_PADDING + (i + 0.5) * GOAL_LINE_SPACING;

        const textMetrics = ctx.measureText(goals[i]);
        ctx.fillStyle = PROGRESS_COLORS.Accent3;

        ctx.fillText(
          goals[i],
          goalDividerX - GOAL_DIVIDER_SPACING - textMetrics.width,
          lineY
        );

        ctx.fillRect(
          goalDividerX,
          lineY - 0.5 * GOAL_LINE_THICKNESS,
          PROGRESS_WIDTH - goalDividerX - PROGRESS_SIDE_PADDING,
          GOAL_LINE_THICKNESS
        );

        for (let j = 0; j < goal_data.length; j++) {
          const x =
            goalDividerX +
            (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING) * j +
            GOAL_SQUARE_SPACING;

          ctx.fillStyle = PROGRESS_COLORS.Accent3;

          if (goal_data[j].vals[i] !== 0) { // !== Unknown
            ctx.fillRect(
              x,
              lineY - 0.5 * GOAL_SQUARE_SIZE,
              GOAL_SQUARE_SIZE,
              GOAL_SQUARE_SIZE
            );

            if(goal_data[j].vals[i] === 1) { // === Failure

              ctx.fillStyle = PROGRESS_COLORS.BG2;
              ctx.fillRect(
                x + GOAL_SQUARE_OUTLINE,
                lineY - 0.5 * GOAL_SQUARE_SIZE + GOAL_SQUARE_OUTLINE,
                GOAL_SQUARE_SIZE - 2.0 * GOAL_SQUARE_OUTLINE,
                GOAL_SQUARE_SIZE - 2.0 * GOAL_SQUARE_OUTLINE
              );
            }
          }
        }
      }

      const dividerXOffset = goalDividerX + 0.5 * GOAL_SQUARE_SPACING;
      ctx.strokeStyle = PROGRESS_COLORS.Accent2;
      ctx.lineWidth = GOAL_DIVIDER_THICKNESS;
      ctx.setLineDash([]);
      ctx.beginPath();
      ctx.moveTo(
        dividerXOffset + (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING) * 4,
        goalDividerMinY
      );
      ctx.lineTo(
        dividerXOffset + (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING) * 4,
        goalDividerMinY + goalDividerHeight
      );
      ctx.stroke();

      ctx.setLineDash([5, 5]);
      ctx.beginPath();
      ctx.moveTo(
        dividerXOffset + (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING) * 12,
        goalDividerMinY
      );
      ctx.lineTo(
        dividerXOffset + (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING) * 12,
        goalDividerMinY + goalDividerHeight
      );
      ctx.stroke();

      for (let j = 0; j < goal_data.length; j += GOAL_TICK_INTERVAL) {
        const x =
          goalDividerX +
          (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING) * j +
          GOAL_SQUARE_SPACING +
          0.5 * GOAL_SQUARE_SIZE;

        ctx.fillStyle = PROGRESS_COLORS.Accent3;
        ctx.fillRect(
          x - 0.5 * GOAL_TICK_THICKNESS,
          goalDividerMinY + goalDividerHeight,
          GOAL_TICK_THICKNESS,
          GOAL_TICK_HEIGHT
        );

        const labelText = goal_data[j].label;
        const textMetrics = ctx.measureText(labelText);
        ctx.fillText(
          labelText,
          x - 0.5 * textMetrics.width,
          goalDividerMinY +
          goalDividerHeight +
          GOAL_TICK_HEIGHT +
          textMetrics.actualBoundingBoxAscent +
          GOAL_TICK_LABEL_SPACING
        );
      }

      const goalOffsetX =
        goalDividerX + GOAL_SQUARE_SPACING + 0.5 * GOAL_SQUARE_SIZE;
      const closestGoal = Math.round(
        (mousePosition.x - goalOffsetX) /
        (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING)
      );
      if (
        goalDividerMinY <= mousePosition.y &&
        mousePosition.y <= goalDividerMinY + goalDividerHeight &&
        0 <= closestGoal &&
        closestGoal < goal_data.length
      ) {
        ctx.fillStyle = PROGRESS_COLORS.Accent2;
        ctx.fillRect(
          goalOffsetX +
          (GOAL_SQUARE_SIZE + GOAL_SQUARE_SPACING) * closestGoal -
          0.5 * GOAL_DIVIDER_THICKNESS,
          goalDividerMinY,
          GOAL_DIVIDER_THICKNESS,
          goalDividerHeight
        );
      }
    },
    [mousePosition.x, mousePosition.y, goals, goal_data]
  );

  useEffect(() => {
    if (!goals || !goal_data) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const handleResize = () => {
      draw(context);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [draw, goals, goal_data]);

  if (!goals || !goal_data) {
    return null;
  }

  return (
    <Box px={3} display="flex" alignItems="center" flex={1} position="relative">
      <Box mb="30px" ref={containerRef} style={{ overflowX: "auto" }}>
        <canvas ref={canvasRef} />
      </Box>
    </Box>
  );
};

export default ProgressChart;
