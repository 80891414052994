export const PROGRESS_TOP_PADDING = 10;
export const PROGRESS_BOTTOM_PADDING = 40;
export const PROGRESS_SIDE_PADDING = 0;
export const GOAL_LINE_SPACING = 28;

export const GOAL_SQUARE_SIZE = 16;
export const GOAL_SQUARE_OUTLINE = 2;
export const GOAL_SQUARE_SPACING = 10;
export const GOAL_DIVIDER_SPACING = 10;
export const GOAL_DIVIDER_THICKNESS = 2;
export const GOAL_LINE_THICKNESS = 2;
export const GOAL_TICK_THICKNESS = 2;
export const GOAL_TICK_HEIGHT = 12;
export const GOAL_TICK_LABEL_SPACING = 4;
export const GOAL_TICK_INTERVAL = 3;

export const PROGRESS_COLORS = {
  BG1: "#021A22",
  BG2: "#042835",
  Accent1: "#36535D",
  Accent2: "#598795",
  Accent3: "#91D9B9",
};
