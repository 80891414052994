import { Box, Typography } from "@mui/material";

const TimelinePopper = ({
  events,
  hiddenEvents, setHiddenEvents,
}) => {
  // Filter events with unique event?.text
  events = events.filter(
    (v, i, a) => a.findIndex((t) => t.text === v.text) === i
  );

  return (
    <Box sx={wrapperStyle}>
      <Typography
        component="div"
        fontSize="20px"
        className="text-primery"
        align="center"
        sx={{ textDecoration: "underline" }}
      >
        Events
      </Typography>

      <Box sx={eventWrapperStyle}>
        {events.map((e, index) => (
          <EventItem
            key={index}
            event={e}
            hiddenEvents={hiddenEvents}
            setHiddenEvents={setHiddenEvents}
          />
        ))}
      </Box>
    </Box>
  );
};

const EventItem = ({ event, hiddenEvents, setHiddenEvents }) => {
  return (
    <Box sx={eventItemStyle}>
      <Box
        sx={{
          ...eventColor,
          bgcolor: event?.color,
        }}
        onClick={() => {
          if (hiddenEvents.includes(event.text)) {
            setHiddenEvents(x => x.filter(y => y !== event.text))
          } else {
            setHiddenEvents(x => [...x, event.text])
          }
        }}
      />
      <Typography
        sx={{
          fontFamily: "Acumin Pro",
          fontSize: "16px !important",
          textDecoration: hiddenEvents.includes(event.text) ? 'line-through' : undefined,
        }}
      >
        {event?.text}
      </Typography>
    </Box>
  );
};

export default TimelinePopper;

const wrapperStyle = {
  border: "1px solid #91D9B9",
  p: "20px",
  width: "400px",
  bgcolor: "#042835",
  color: "#fff",
  borderRadius: "10px",
};

const eventWrapperStyle = {
  mt: 1.5,
  display: "flex",
  flexWrap: "wrap",
};

const eventItemStyle = {
  flex: "1 0 48%",
  height: "40px",
  alignItems: "center",
  display: "flex",
  pt: 1,
  gap: "5px",
};

const eventColor = {
  height: "30px",
  width: "30px",
  cursor: "pointer",
};
