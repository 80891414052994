import React from 'react'
import { Outlet, useLocation } from "react-router-dom";
import { Box} from "@mui/material";
import SideBar from '../SideBar/SideBar';
import Header from '../Header/Header';
import { useAuth } from '../../Context/AuthContext';
import { Toaster } from 'react-hot-toast';



function Layout() {
    const { user } = useAuth();
    const location = useLocation();
    return (
        <>
            <Box className='dashboard-container'>
                <Toaster position="top-right" reverseOrder={false} />
                <Box className="left-container">
                    <SideBar user={user} />
                </Box>
                <Box className="right-container">
                    <Header />
                    <Box className={"main-contant " + (location.pathname === '/my-profile' ? 'profile-page' : null)} >
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Layout