import React, { useEffect, useState } from "react";
import "../MyPatients/MyPatients.css";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import AddIcon from "@mui/icons-material/Add";
import AddPhysician from "./Add physician/AddPhysician";
import { GetApi, GetApiParam } from "../../Api/Api";
import PhysicianTable from "./components/PhysicianTable";
import { Toaster } from "react-hot-toast";
import { HOSPITAL_ROUTE, USER_ROUTE } from "../../Api/Routes";

const Physician = () => {
  const [open, setOpen] = useState(false);
  const [physicianData, setPhysicianData] = useState([]);
  const [hospitalData, setHospitalData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPhysicianData = async () => {
    try {
      const response = await GetApiParam(USER_ROUTE, { role: "Physician" });
      const responseHospitals = await GetApi(HOSPITAL_ROUTE);

      const responseUsers = response?.data?.users || [];

      // map physician.hospitalId to hospital name
      const hospitalData = responseHospitals?.data?.hospitals || [];
      responseUsers.forEach((physician) => {
        const hospital = hospitalData.find(
          (hospital) => hospital.id === physician.hospital_id
        );
        physician.hospitalName = hospital?.name;
      });

      setPhysicianData(responseUsers || []);
      setHospitalData(hospitalData || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhysicianData();
  }, []);

  const AddUpdatePhysician = () => {
    fetchPhysicianData();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <CircularProgress style={{ margin: "20px" }} />;
  }

  return (
    <Box>
      <Toaster position="top-right" reverseOrder={false} />
      <Box className="my-patients-topbar" mt={3}>
        <Box>
          <Typography className="title" component="h4">
            Physicians
          </Typography>
        </Box>
        <Box className="right btn-font-small">
          <Stack direction="row" gap={2}>
            <ButtonComponent
              buttonText="Add physician"
              buttonVariant="contained"
              buttonStyle={{ borderRadius: "100px" }}
              startIcon={<AddIcon />}
              onClick={handleOpen}
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ mt: "30px" }}>
        <PhysicianTable
          physicianData={physicianData}
          hospitalData={hospitalData}
          AddUpdatePhysician={AddUpdatePhysician}
        />
      </Box>
      <AddPhysician
        open={open}
        handleClose={handleClose}
        AddUpdatePhysician={AddUpdatePhysician}
        physicianData={physicianData}
        hospitalData={hospitalData}
      />
    </Box>
  );
};

export default Physician;
