import React, { createContext, useContext, useState } from 'react';

const TimeZoneContext = createContext();

export const TimeZoneProvider = ({ children }) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState("America/Los_Angeles");
  const [timeZoneLabel, setTimeZoneLabel] = useState("PST (America / Los_Angeles)");

  return (
    <TimeZoneContext.Provider value={{ selectedTimeZone, setSelectedTimeZone, timeZoneLabel, setTimeZoneLabel }}>
      {children}
    </TimeZoneContext.Provider>
  );
};

export const useTimeZone = () => {
  const context = useContext(TimeZoneContext);
  if (!context) {
    throw new Error("useTimeZone must be used within a TimeZoneProvider");
  }
  return context;
};
