import {
  Box,
  Paper,
  InputBase,
  IconButton,
  Menu,
  Button,
  MenuItem,
  Typography,
  Drawer,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import "./Header.css";
import "../../Assets/Css/Responsive.css";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import logo from "./../../Assets/Images/Login/logo.svg";
import SideBar from "../SideBar/SideBar";
import { useSearch } from "../../Context/SearchContext";
import Profile from "../../Screens/Profile/Profile";
import ChangePassword from "../../Screens/Profile/ChangePassword";
import { basePath } from "../../routes/Config";

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const { searchQuery, setSearchQuery } = useSearch();
  const [openProfile, setOpenProfile] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const open = Boolean(anchorEl);

  const location = useLocation();
  const [showSearch, setShowSearch] = useState(true);

  const menuRef = useRef();

  const userDetails = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    setShowSearch(
      !(
        currentPath === "/" ||
        currentPath === "/sensors-monitoring" ||
        currentPath === "/graph-monitoring"
      )
    );
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("isLoggedIn");
    window.history.replaceState({}, "", `${basePath}login`);
    navigate(`${basePath}login`, { state: { from: basePath } });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setShowSidebar(newOpen);
  };

  const handleProfileOpen = () => {
    setOpenProfile(true);
    handleClose();
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
  };

  const handleChangePassword = () => {
    setOpenChangePassword(true);
    handleClose();
  };

  const handleChangePasswordClose = () => {
    setOpenChangePassword(false);
  };

  return (
    <Box>
      <Box className="header-container">
        <Box className="left-section">
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box className="header-menu-toggle">
              <Box className="mobile-logo">
                <Link to="#">
                  <img src={logo} alt="Logo" />
                </Link>
              </Box>
              <Box className="toggle-btn-box">
                <Button onClick={toggleDrawer(true)}>
                  <ReorderIcon />
                </Button>
                <Drawer open={showSidebar} onClose={toggleDrawer(false)}>
                  <SideBar />
                </Drawer>
              </Box>
            </Box>
            {showSearch && (
              <Paper component="form" className="search-input">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="ex. search"
                  inputProps={{ "aria-label": "search google maps" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon sx={{ color: "var(--whiteLight)" }} />
                </IconButton>
              </Paper>
            )}
          </Box>
        </Box>
        <Box className="right-section">
          <Box className="notification-box">
            <NotificationsIcon sx={{ color: "#fff" }} />
          </Box>
          <Box className="header-profile--wrapper">
            <Button
              id="demo-customized-button"
              className="h-profile--btn"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "#d3d3d3",
                  color: "black",
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {userDetails?.first_name.charAt(0)}
              </Box>
              <Typography
                component="span"
                sx={{ marginLeft: 1, whiteSpace: "nowrap" }}
              >
                {userDetails?.title}. {userDetails?.first_name}
              </Typography>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              className="header-menu"
              anchorEl={anchorEl}
              open={open}
              ref={menuRef}
              onClose={handleClose}
            >
              <MenuItem onClick={handleProfileOpen} disableRipple>
                View Profile
              </MenuItem>

              <MenuItem onClick={handleChangePassword} disableRipple>
                Change Password
              </MenuItem>
              <MenuItem onClick={handleLogout} disableRipple>
                Logout
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>
      </Box>
      <ChangePassword
        open={openChangePassword}
        handleClose={handleChangePasswordClose}
      />
      <Profile open={openProfile} handleClose={handleProfileClose} />
    </Box>
  );
};

export default Header;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "0 0 6px 6px",
    marginTop: theme.spacing(1),
    minWidth: 200,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
