// AuthProvider.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  // const [role, setRole] = useState();

  const login = (accessToken, userData) => {
  const userRole = userData?.role ? 'Admin' : 'Physician';

    setUser({
      ...userData,
      role : userRole,
      accessToken,
    });
  };

  const logout = () => {
    // Implement your logout logic here
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
