import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { rawTimeZones } from "@vvo/tzdb";
import {
  LocalizationProvider,
  TimePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import Input from "../../../../Components/InputComponent/Input";
import ButtonComponent from "../../../../Components/ButtonComponent/ButtonComponent";
import { GetApiParam, postApi, GetApi } from "../../../../Api/Api";
import { DEVICE_ROUTE, PATIENT_ROUTE } from "../../../../Api/Routes";
import { genderOptions, heightOptions, weightOptions } from "./constants";
import "react-phone-number-input/style.css";
import "./AddPatients.css";

const AddPatients = (props) => {
  const {
    handleClose,
    open,
    editValue,
    patientId,
    AddUpdatePatient,
    physicianData,
  } = props;
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [selectedPhysician, setSelectedPhysician] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.role === "Admin";

  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const physicianId_watch = watch("physician_id");

  // only allow devices that have not been assigned yet
  const fetchDevices = async () => {
    try {
      const response = await GetApi(DEVICE_ROUTE);
      const responseData = response?.data?.devices;
      setDeviceList(responseData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  // sets it so that selectedDevice will be the full device instead of just the name
  const handleSelectedDevice = (event) => {
    if (event.target.value !== "") {
      const device = deviceList.find((d) => d.id === event.target.value);
      setSelectedDevice(device);
    } else {
      setSelectedDevice("");
    }
  };

  useEffect(() => {
    if (editValue && patientId) {
      const fetchPatientDetails = async () => {
        try {
          const response = await GetApiParam(`${PATIENT_ROUTE}/${patientId}`);
          const patientDetails = response?.data;
          setValue("title", patientDetails?.title);
          setValue("name", patientDetails?.name);
          setValue("nick_name", patientDetails?.nick_name);
          setValue("code", patientDetails?.code);
          setValue("reference_name", patientDetails?.reference_name);
          setValue("age", patientDetails?.age);
          setValue("gender", patientDetails?.gender);
          setValue("weight", patientDetails?.weight.toString());
          setValue("height", patientDetails?.height.toString());
          setValue("phone_no", patientDetails?.phone_no);
          setValue("email_id", patientDetails?.email_id);
          setValue("disease", patientDetails?.disease);
          setValue("physician_name", patientDetails?.physician_name);
          setValue("device_id", patientDetails?.device_id);
          setValue("physician_id", patientDetails?.physician_id);

          setValue(
            "timezone",
            rawTimeZones.find((tz) => tz.name === patientDetails?.timezone)
          );
          setValue(
            "night_start",
            dayjs(patientDetails?.night_start, "HH:mm:ss.S")
          );
          setValue("night_end", dayjs(patientDetails?.night_end, "HH:mm:ss.S"));
          if (patientDetails?.start_treatment) {
            setValue(
              "start_treatment",
              dayjs(patientDetails?.start_treatment, "YYYY-MM-DD")
            );
          } else {
            setValue("start_treatment", null);
          }
          if (patientDetails?.end_treatment) {
            setValue(
              "end_treatment",
              dayjs(patientDetails?.end_treatment, "YYYY-MM-DD")
            );
          } else {
            setValue("end_treatment", null);
          }
          // for making dropdown show correct associated device_id/physician instead of 'None'
          const selectDropdownDevice = deviceList.find(
            (d) => d?.id === patientDetails.device_id
          );
          const selectDropdownPhysician = physicianData.find(
            (p) => p.last_name === patientDetails.physician_name
          );
          setSelectedDevice(selectDropdownDevice);
          setSelectedPhysician(selectDropdownPhysician);
        } catch (error) {
          console.error("Error fetching patient details:", error);
        }
      };
      fetchPatientDetails();
    }
  }, [editValue, patientId, setValue, deviceList, physicianData]);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    parseAndSetDefault(data, "age");
    parseAndSetDefault(data, "weight");
    parseAndSetDefault(data, "height");

    const formData = {
      ...data,
      device_id: selectedDevice?.id ?? null,
      physician_id: isAdmin ? data.physician_id : user.id,
      params: {},

      timezone: data.timezone.name,
      night_start: data.night_start.format("HH:mm:ss.SSS"),
      night_end: data.night_end.format("HH:mm:ss.SSS"),
      start_treatment: data.start_treatment
        ? data.start_treatment.format("YYYY-MM-DD")
        : null,
      end_treatment: data.end_treatment
        ? data.end_treatment.format("YYYY-MM-DD")
        : null,
    };

    try {
      const responseData = await postApi(
        editValue ? `${PATIENT_ROUTE}/${patientId}` : PATIENT_ROUTE,
        editValue ? { ...formData, id: patientId } : formData
      );
      if (responseData?.status === 200) {
        if (responseData?.data) {
          reset();
          handleClose();
          toast.success(editValue ? "Patient Updated" : "Patient Added");
          AddUpdatePatient();
        } else {
          toast.error(
            editValue ? "Patient Updated Failed" : "Patient Creation Failed."
          );
        }
      } else {
        toast.error("Something went wrong.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // Only show controllers assigned to the selected physician
  const filteredControllers = useMemo(() => {
    const physician_id = physicianId_watch;
    return deviceList.filter((x) => x?.physician_id === physician_id);
  }, [deviceList, physicianId_watch]);

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="modal-shadow">
            <Box className="modal-header">
              <Typography
                id="modal-modal-title"
                className="text-primery"
                variant="h4"
                component="h4"
              >
                {editValue ? "Update Patient" : "Add Patient"}
              </Typography>
              <Button onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </Box>
            {/* form submit box */}
            <Box
              className="modal-body"
              id="modal-modal-description"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group select-form-group">
                      <FormControl fullWidth>
                        <InputLabel shrink className="input-label">
                          Title
                        </InputLabel>
                        <Controller
                          name="title"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              displayEmpty
                              onChange={(e) => {
                                setValue("title", e.target.value);
                                field.onChange(e);
                              }}
                            >
                              <MenuItem value="">
                                <span>Mr / Ms</span>
                              </MenuItem>
                              <MenuItem value="Mr">Mr.</MenuItem>
                              <MenuItem value="Ms">Miss</MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient First Name"
                        placeholder="Lindsey"
                        type="text"
                        name="name"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: "Patient Name is required",
                        }}
                      />
                      {errors.name && (
                        <p className="error-message">{errors.name.message}</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient Nick Name"
                        placeholder="Pitts"
                        type="text"
                        name="nick_name"
                        defaultValue=""
                        register={register}
                      />
                      {errors.nick_name && (
                        <p className="error-message">
                          {errors.nick_name.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Patient Code"
                        placeholder="409866"
                        type="number"
                        name="code"
                        defaultValue=""
                        register={register}
                      />
                      {errors.code && (
                        <p className="error-message">{errors.code.message}</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Reference Name"
                        placeholder="Lindsey"
                        type="text"
                        defaultValue=""
                        name="reference_name"
                        register={register}
                      />
                      {errors.reference_name && (
                        <p className="error-message">
                          {errors.reference_name.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Box className="form-group">
                      <Input
                        label="Age"
                        placeholder="Age"
                        type="number"
                        name="age"
                        register={register}
                      />
                      {errors.age && (
                        <p className="error-message">{errors.age.message}</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className="input-label">
                            Gender
                          </InputLabel>
                          <Controller
                            name="gender"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={genderOptions}
                                getOptionLabel={(option) => option}
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(_, value) =>
                                  setValue("gender", value)
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className="input-label">
                            Weight (lbs)
                          </InputLabel>
                          <Controller
                            name="weight"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={weightOptions}
                                getOptionLabel={(option) => option} // Specify how to get the label
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField {...params} type="number" />
                                )}
                                onChange={(_, value) =>
                                  setValue("weight", value)
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className="input-label">
                            Height (in)
                          </InputLabel>
                          <Controller
                            name="height"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={heightOptions}
                                getOptionLabel={(option) => option}
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(_, value) =>
                                  setValue("height", value)
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel
                            shrink
                            className="input-label"
                            sx={{
                              "& .MuiFormLabel-asterisk": {
                                color: "red",
                              },
                            }}
                            required
                          >
                            Timezone
                          </InputLabel>
                          <Controller
                            name="timezone"
                            control={control}
                            rules={{ required: "Timezone is required" }}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={rawTimeZones}
                                getOptionLabel={(option) =>
                                  option.rawFormat ?? ""
                                }
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(_, value) => field.onChange(value)}
                              />
                            )}
                          />
                          {errors.timezone && (
                            <p className="error-message">
                              {errors.timezone.message}
                            </p>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Disease"
                        placeholder="Disease"
                        type="text"
                        name="disease"
                        defaultValue=""
                        register={register}
                      />
                      {errors.disease && (
                        <p className="error-message">
                          {errors.disease.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={6} md={3}>
                      <Box className="form-group">
                        <Box className="select-opation--wrapper">
                          <FormControl variant="standard">
                            <InputLabel
                              shrink
                              className="input-label"
                              sx={{
                                mb: "2px",
                                "& .MuiFormLabel-asterisk": {
                                  color: "red",
                                },
                              }}
                              required
                            >
                              Night Start Time
                            </InputLabel>
                            <Controller
                              name="night_start"
                              control={control}
                              rules={{
                                required: "Required",
                              }}
                              render={({ field }) => (
                                <TimePicker
                                  {...field}
                                  views={["hours", "minutes"]}
                                  onChange={field.onChange}
                                  sx={{
                                    ".MuiInputBase-root": {
                                      py: "3px !important",
                                    },
                                  }}
                                />
                              )}
                            />
                            {errors.night_start && (
                              <p className="error-message">
                                {errors.night_start.message}
                              </p>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Box className="form-group">
                        <Box className="select-opation--wrapper">
                          <FormControl variant="standard">
                            <InputLabel
                              shrink
                              className="input-label"
                              required
                              sx={{
                                mb: "2px",
                                "& .MuiFormLabel-asterisk": {
                                  color: "red",
                                },
                              }}
                            >
                              Night End Time
                            </InputLabel>
                            <Controller
                              name="night_end"
                              control={control}
                              rules={{ required: "Required" }}
                              render={({ field }) => {
                                return (
                                  <TimePicker
                                    {...field}
                                    value={field.value ?? null}
                                    views={["hours", "minutes"]}
                                    onChange={field.onChange}
                                    sx={{
                                      ".MuiInputBase-root": {
                                        py: "3px !important",
                                      },
                                    }}
                                  />
                                );
                              }}
                            />
                            {errors.night_end && (
                              <p className="error-message">
                                {errors.night_end.message}
                              </p>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box className="form-group">
                        <Box className="select-opation--wrapper">
                          <FormControl variant="standard">
                            <InputLabel
                              shrink
                              className="input-label"
                              sx={{ mb: "2px" }}
                            >
                              Treatment Start Date
                            </InputLabel>
                            <Controller
                              name="start_treatment"
                              control={control}
                              defaultValue=""
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    {...field}
                                    value={field.value ?? null}
                                    onChange={field.onChange}
                                    sx={{
                                      ".MuiInputBase-root": {
                                        py: "3px !important",
                                      },
                                    }}
                                  />
                                );
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box className="form-group">
                        <Box className="select-opation--wrapper">
                          <FormControl variant="standard">
                            <InputLabel
                              shrink
                              className="input-label"
                              sx={{ mb: "2px" }}
                            >
                              Treatment End Date
                            </InputLabel>
                            <Controller
                              name="end_treatment"
                              control={control}
                              defaultValue=""
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    {...field}
                                    value={field.value ?? null}
                                    onChange={field.onChange}
                                    sx={{
                                      ".MuiInputBase-root": {
                                        py: "3px !important",
                                      },
                                    }}
                                  />
                                );
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Box>
                    </Grid>
                  </LocalizationProvider>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="cstm-phone--input input-box">
                        <InputLabel className="cstm-label">
                          Phone Number
                        </InputLabel>
                        <FormControl variant="standard">
                          <Controller
                            name="phone_no"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <PhoneInput
                                {...field}
                                country={"us"}
                                label="Phone number"
                                placeholder="000-000-0000"
                              />
                            )}
                          />
                        </FormControl>

                        {errors.phone_no && (
                          <p className="error-message">
                            {errors.phone_no.message}
                          </p>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="Enter your Email"
                        type="email"
                        name="email_id"
                        register={register}
                      />
                      {errors.email && (
                        <p className="error-message">
                          {errors.email_id.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  {/* Physician Dropdown for Admins */}
                  {isAdmin && (
                    <Grid item xs={12} sm={6} md={6}>
                      <Box className="form-group">
                        <InputLabel
                          className="input-label"
                          required
                          sx={{
                            "& .MuiFormLabel-asterisk": {
                              color: "red",
                            },
                          }}
                        >
                          Select Physician
                        </InputLabel>
                        <FormControl>
                          <Controller
                            name="physician_id"
                            rules={{ required: "Physician is required" }}
                            control={control}
                            defaultValue={""}
                            value={selectedPhysician?.id || ""}
                            render={({ field }) => (
                              <Select
                                displayEmpty
                                {...field}
                                onChange={(e) => {
                                  // reset device when Phys. is changed
                                  setSelectedDevice(null)
                                  field.onChange(e);
                                }}
                              >
                                <MenuItem value="">None</MenuItem>
                                {physicianData &&
                                  physicianData.map((physician) => (
                                    <MenuItem
                                      key={physician.id}
                                      value={physician.id}
                                    >
                                      {physician.first_name}{" "}
                                      {physician.last_name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                        {errors.physician_id && (
                          <p className="error-message">
                            {errors.physician_id.message}
                          </p>
                        )}
                      </Box>
                    </Grid>
                  )}
                  {/* controller dropdown */}
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <InputLabel className="cstm-label">
                        Select Controller
                      </InputLabel>
                      <Select
                        value={selectedDevice?.id || ""}
                        onChange={handleSelectedDevice}
                        displayEmpty
                        inputProps={{ "aria-label": "Select Data Type" }}
                        className="dropdown"
                      >
                        <MenuItem value=""> None </MenuItem>
                        {filteredControllers.map((device) => (
                          <MenuItem key={device?.id} value={device?.id}>
                            {device?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-center">
                        <ButtonComponent
                          buttonText={
                            editValue ? "Update Patient" : "Add Patient"
                          }
                          buttonVariant="contained"
                          type="submit"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddPatients;

const style = {
  position: "absolute",
  top: "50%",
  left: 15,
  right: 15,
  transform: "translateY(-50%)",
  maxWidth: 1000,
  color: "#ffffff",
  boxShadow: 24,
  margin: "1rem auto",
  maxHeight: "100%",
};

const parseAndSetDefault = (data, field) => {
  if (!data) return;

  if (data?.[field]) {
    data[field] = parseInt(data[field]) || 0;
  } else {
    data[field] = 0;
  }
};
