import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/router";
import { SearchProvider } from "./Context/SearchContext";
import { TimeZoneProvider } from "./Context/TimeZoneContext";
import { DeviceProvider } from "./Context/DeviceContext";

function App() {
  return (
    <SearchProvider>
      <TimeZoneProvider>
        <DeviceProvider>
          <BrowserRouter>
            <div className="App">
              <AppRoutes />
            </div>
          </BrowserRouter>
        </DeviceProvider>
      </TimeZoneProvider>
    </SearchProvider>
  );
}

export default App;
