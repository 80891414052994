import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { Info } from "@mui/icons-material";
import { Box, Popper, Typography } from "@mui/material";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import toast from "react-hot-toast";

import TimelinePopper from "./TimelinePopper";
import { getPatientTimeline, getPatientEventAvailableDates } from "../../../../Api/AdapterApi";
import CalendarDay from "../../../../Components/CalendarDay";
import TimelineChart from "../../../../Components/TimelineChartComponent";
import { parseDateTime, parseDate } from "../../../../utils/helpers";


const TimelineCard = ({
  patientId,
  defaultStart,
  defaultEnd,
}) => {
  const [startTime, setStartTime] = useState(defaultStart);
  const [endTime, setEndTime] = useState(defaultEnd);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hiddenEvents, setHiddenEvents] = useState([]);
  const [highlightedStartDays, setHighlightedStartDays] = useState([]);
  const [highlightedEndDays, setHighlightedEndDays] = useState([]);
  const [loadingCalendar, setIsLoadingCalendar] = useState(false);

  const [data, setData] = useState();

  const requestAbortController = useRef(null);

  const debug = useMemo(() => {
    let params = new URL(document.location.toString()).searchParams;
    return params.get("debug") !== null
  }, [document.location])

  const fetchTimelineData = useCallback(async () => {
    if (!patientId || !startTime || !endTime || startTime >= endTime) return;

    try {
      const params = {
        patient_id: patientId,
        start: parseDateTime(startTime),
        end: parseDateTime(endTime),
        debug: debug,
      };
      const response = await getPatientTimeline(params);
      response.startTime = new Date(startTime);
      if (response?.minutes) {
        setData(response);
      }
    } catch (error) {
      console.log("Error fetching timeline data:", error);
    }
  }, [patientId, startTime, endTime, debug]);

  useEffect(() => {
    fetchTimelineData();
  }, [fetchTimelineData]);

  const handleStartTimeChange = (date) => {
    toast.remove();
    if (endTime && date >= endTime) {
      toast.error("Start date should be before end date");
    }
    setStartTime(date);
  };

  const handleEndTimeChange = (date) => {
    toast.remove();
    if (startTime && startTime >= date) {
      toast.error("End date should be after start date");
    }
    setEndTime(date);
  };

  const handleInfoClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const anchorId = open ? "simple-popper" : undefined;

  const fetchHighlightedDays = async (date, setHighlightedDays) => {
    const controller = new AbortController();
    try {
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const params = {
        patient_id: patientId,
        start: parseDate(startOfMonth),
        end: parseDate(endOfMonth),
      };
      const dates = await getPatientEventAvailableDates(params, controller.signal);
      setHighlightedDays(dates?.dates?.map((date) => parseInt(date.split("-")[2], 10)));
    } catch (error) {
      console.error("Error fetching highlighted days:", error);
      setHighlightedDays([]);
    }
    setIsLoadingCalendar(false);
    requestAbortController.current = controller;
  };

  const handleMonthChange = (date, setHighlightedDays) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setIsLoadingCalendar(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date, setHighlightedDays);
  }

  useEffect(() => {
    fetchHighlightedDays(startTime, setHighlightedStartDays);
    fetchHighlightedDays(endTime, setHighlightedEndDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box id="timeline-container">
      <Box className="my-patients-topbar" mt={3}>
        <Typography className="title" component="h4">
          Timeline{" "}
          {data?.events && data?.events.length > 0 && (
            <Info
              aria-describedby={anchorId}
              onClick={handleInfoClick}
              sx={{ cursor: "pointer" }}
            />
          )}
        </Typography>

        <Popper
          id={anchorId}
          open={open}
          anchorEl={anchorEl}
          placement="top-start"
        >
          <TimelinePopper
            events={data?.events}
            hiddenEvents={hiddenEvents}
            setHiddenEvents={setHiddenEvents}
          />
        </Popper>
        <Box display="flex" gap="20px">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box className="select-option">
              <DesktopDateTimePicker
                format="yyyy/MM/dd hh:mm a"
                onChange={handleStartTimeChange}
                value={startTime}
                closeOnSelect={false}
                onMonthChange={(date) => handleMonthChange(date, setHighlightedStartDays)}
                loading={loadingCalendar}
                slots={{ day: (props) => <CalendarDay {...props} highlightedDays={highlightedStartDays} /> }}
                disableFuture
              />
            </Box>
            <Box className="select-option">
              <DesktopDateTimePicker
                format="yyyy/MM/dd hh:mm a"
                onChange={handleEndTimeChange}
                value={endTime}
                closeOnSelect={false}
                onMonthChange={(date) => handleMonthChange(date, setHighlightedEndDays)}
                loading={loadingCalendar}
                slots={{ day: (props) => <CalendarDay {...props} highlightedDays={highlightedEndDays} /> }}
              />
            </Box>
          </LocalizationProvider>
        </Box>
      </Box>

      {!data ? (
        <Box
          display="flex"
          alignItems="center"
          bgcolor={"#042835"}
          justifyContent="center"
          p={5}
        >
          <Typography component="h4" className="text-primery" align="center">
            Select dates
          </Typography>
        </Box>
      ) : (
        <TimelineChart
          data={data}
          hiddenEvents={hiddenEvents}
        />
      )}
    </Box>
  );
};

export default TimelineCard;
