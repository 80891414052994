import { Box, Typography } from "@mui/material";
import ProgressChart from "../../../../Components/ProgressChartComponent";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useCallback, useEffect, useState } from "react";
import { getPatientProgress } from "../../../../Api/AdapterApi";
import { parseDate } from "../../../../utils/helpers";
import toast from "react-hot-toast";

const ProgressCard = ({
  patientId,
  defaultStart,
  defaultEnd,
}) => {
  const [goals, setGoals] = useState(null);
  const [axes, setAxes] = useState([]);
  const [startTime, setStartTime] = useState(defaultStart);
  const [endTime, setEndTime] = useState(defaultEnd);

  const fetchProgressData = useCallback(async () => {
    if (!patientId || !startTime || !endTime || startTime >= endTime) return;

    try {
      const params = {
        patient_id: patientId,
        start: parseDate(startTime),
        end: parseDate(endTime),
      };
      const response = await getPatientProgress(params);

      if (response?.goals) {
        const parsed = parseData(response);
        setGoals(parsed.goals);
        setAxes(parsed.goal_data);
      }
    } catch (error) {
      console.log("Error fetching progress data:", error);
    }
  }, [patientId, startTime, endTime]);

  useEffect(() => {
    fetchProgressData();
  }, [fetchProgressData]);

  const handleStartTimeChange = (date) => {
    toast.remove();
    if (endTime && date >= endTime) {
      toast.error("Start date should be before end date");
    }
    setStartTime(date);
  };

  const handleEndTimeChange = (date) => {
    toast.remove();
    if (startTime && startTime >= date) {
      toast.error("End date should be after start date");
    }
    setEndTime(date);
  };

  return (
    <Box className="main-card" display="flex" flexDirection="column">
      <Box className="main-card-header">
        <Typography component="h4" className="text-primery text--left">
          Progress
        </Typography>
        <Box display="flex" gap="20px">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box className="select-option">
              <DesktopDatePicker
                disableFuture
                format="yyyy/MM/dd"
                onChange={handleStartTimeChange}
                value={startTime}
                closeOnSelect={false}
              />
            </Box>
            <Box className="select-option">
              <DesktopDatePicker
                disableFuture
                format="yyyy/MM/dd"
                onChange={handleEndTimeChange}
                value={endTime}
                closeOnSelect={false}
              />
            </Box>
          </LocalizationProvider>
        </Box>
      </Box>
      {!goals || !axes || goals.length === 0 || axes.length === 0 ? (
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          position="relative"
          justifyContent="center"
        >
          <Typography component="h4" className="text-primery" align="center">
            {!startTime || !endTime ? "Select dates" : "No data"}
          </Typography>
        </Box>
      ) : (
        <ProgressChart goals={goals} goal_data={axes} />
      )}
    </Box>
  );
};

const parseData = (rawResponse) => {
  const goalsArray = rawResponse.goals || [];
  const xsArray = rawResponse.xs || [];

  const goals = goalsArray.map((goal) => goal.name);

  const goalData = xsArray.map((xs, index) => {
    const ys = goalsArray.map((goal) => goal.ys[index]);
    return { label: xs, vals: ys };
  });

  return { goals, goal_data: goalData };
};

export default ProgressCard;
