export const parseJwt = (str) => {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

export const parseDate = (date) => {
  const parsedDate = new Date(date);
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}${month}${day}`;
  return formattedDate;
};

export const parseDateTime = (date) => {
  const parsedDate = new Date(date);
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const hours = String(parsedDate.getHours()).padStart(2, "0");
  const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
  const formattedDate = `${year}${month}${day}${hours}${minutes}`;
  return formattedDate;
};

export const parseError = (responseData) => {
  if (responseData?.response?.data?.reason) {
    return responseData.response.data.reason.replace("Bad request: ", "");
  }
  return null;
};

export const getCurrentUserId = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.id;
};

export const formatDurationUnits = (durations) => {
  let unit = "secs";
  const maxDuration = Math.max(...durations);
  if (maxDuration >= 60 * 60) {
    unit = "hrs";
  } else if (maxDuration >= 60) {
    unit = "mins";
  }

  if (unit === "secs") {
    return [durations, unit];
  }
  if (unit === "mins") {
    return [durations.map((duration) => (duration / 60).toFixed(2)), unit];
  }
  return [durations.map((duration) => (duration / 60 / 60).toFixed(2)), unit];
};
