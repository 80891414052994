import { GetApi } from "./Api";

// params = {patient_id: int, start: string, end: string}
export const getPatientProgress = async (params) => {
  const url = `/patients/${params?.patient_id}/progress?start=${params?.start}&end=${params?.end}`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {patient_id: int, start: string, end: string}
export const getPatientTimeline = async (params) => {
  const url = `/patients/${params?.patient_id}/timeline?start=${params?.start}&end=${params?.end}&debug=${params?.debug}`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {patient_id: int, day: string}
export const getPatientEvents = async (params) => {
  const url = `/patients/${params?.patient_id}/events?day=${params?.day}`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {patient_id: int, event_type_id: string, graph_id: string}
export const getPatientEventGraph = async (params) => {
  const url = `/patients/${params?.patient_id}/graph/${params?.event_type_id}?start=${params?.start}&end=${params?.end}`;
  const result = await GetApi(url);

  return result?.data;
};

// params = {patient_id: int, start: string, end: string}
export const getPatientEventAvailableDates = async (params) => {
  const url = `/patients/${params?.patient_id}/events/available_dates?start=${params?.start}&end=${params?.end}`;
  const result = await GetApi(url);
  return result?.data;
};
