import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';
import {ReactComponent as Warning} from "../Assets/Images/Dashboard/warning.svg"


const ConfirmationDialog = ({ open, title, content, onConfirm, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} className="dialog-component" aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" component="h5" className='modal-title'>{title}</DialogTitle>
      <DialogContent className="text-white">
      <Warning/>
        <DialogContentText id="alert-dialog-description">
            {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{padding: "0 1rem 1rem 1rem", gap: '0.5rem'}} >
        <Button onClick={onClose} className="cancel-btn">
          Cancel
        </Button>
        <Button onClick={onConfirm} autoFocus className="primary-btn">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;


