export const EVENT_LINE_PADDING = 10;
export const TIMELINE_TOP_PADDING = 30;
export const TIMELINE_BOTTOM_PADDING = 45;
export const TIMELINE_SIDE_PADDING = 35;

export const BASELINE_HEIGHT = 30;
export const EVENT_LINE_HEIGHT = 10;
export const TIMELIME_AXIS_THICKNESS = 2;

export const TIMELINE_TICK_THICKNESS = 2;
export const TIMELINE_TICK_HEIGHT = 12;
export const TIMELINE_TICK_LABEL_SPACING = 4;
export const TIMELINE_SHORT_TICK_HEIGHT = 8;

export const TIMELINE_EVENT_OUTLINE_THICKNESS = 2;
export const TIMELINE_TOOLTIP_OUTLINE_THICKNESS = 2;
export const TIMELINE_TOOLTIP_PADDING = 10;

export const TIMELINE_TIME_OFFSET_X = 6;
export const TIMELINE_TIME_OFFSET_Y = 16;

export const TIMELINE_MIN_X = 0;

export const MIN_HOUR_LABEL_SPACING = 40;
export const SHORT_TICK_MIN_SPACING = 20;
export const SHORT_TICK_INCREMENT_OPTIONS = [1, 5, 10, 15, 30];

export const TIMELINE_COLORS = {
  BG1: "#021A22",
  BG2: "#042835",
  Accent1: "#36535D",
  Accent2: "#598795",
  Accent3: "#91D9B9",
};
