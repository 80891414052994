import axiosInstance from "./AuthServices";

export const postLogin = async (endpoint, data) => {
  try {
    const response = await axiosInstance.post(endpoint, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export const postRequest = async (endpoint, config, data) => {
  try {
    const response = await axiosInstance.post(endpoint, data, config);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};
