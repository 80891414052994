// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-card-container {
    border-radius: 10px;
    padding: 18px 16px;
    background-color: #FFA6C7;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dashboard-card-container .card-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
   margin-top: 12px;
}


.dashboard-card-container .card-title {
    font-weight: 400;
    color: var(--darkGreen);
    text-align: left;
}
.dashboard-card-container .card-content .count-number{
    color: var(--darkGreen);
   
}`, "",{"version":3,"sources":["webpack://./src/Components/DashboardComponents/DashboardCard/DashboardCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;GACpB,gBAAgB;AACnB;;;AAGA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,uBAAuB;;AAE3B","sourcesContent":[".dashboard-card-container {\n    border-radius: 10px;\n    padding: 18px 16px;\n    background-color: #FFA6C7;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.dashboard-card-container .card-content{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n   margin-top: 12px;\n}\n\n\n.dashboard-card-container .card-title {\n    font-weight: 400;\n    color: var(--darkGreen);\n    text-align: left;\n}\n.dashboard-card-container .card-content .count-number{\n    color: var(--darkGreen);\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
