// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPopover-root .MuiPopover-paper {
    box-shadow: 0 4px 10px rgb(255 255 255/25%);
}
.MuiPopover-paper ul {
    background-color: var(--darkGreen);
    padding: 0;
}
.MuiPopover-paper ul li{
    padding-top: 10px;
    padding-bottom: 10px;
}
.MuiPopover-paper ul li {
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--whiteLight);
    border-bottom: 1px solid var(--whiteLight);
}
.MuiPopover-paper ul li:last-child{
    border: none;
}
.MuiPopover-paper ul li  a{
    color: var(--whiteLight);
    text-decoration: none;
}
.MuiPopover-paper ul li:hover,
.MuiPopover-paper ul li.Mui-selected{
    background-color: rgba(145, 217, 184, 0.2);
    color: var(--white);
}
.MuiPopover-paper ul li:hover a{
    color: var(--white);
}
.select-option fieldset,

.select-option .MuiInputBase-formControl:hover fieldset {
    border-color: var(--whiteLight);
}
.select-option .MuiInputBase-colorPrimary.Mui-focused fieldset{
    border-color: var(--primeryColor);
}
.select-option .MuiSelect-select {
    color: var(--whiteLight);
}
.select-option .MuiInputBase-input {
    color: var(--whiteLight);
}
.select-option .MuiInputBase-colorPrimary svg {
    fill: var(--whiteLight);
}

`, "",{"version":3,"sources":["webpack://./src/Components/SelectComponent/SelectComponent.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;AAC/C;AACA;IACI,kCAAkC;IAClC,UAAU;AACd;AACA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,wBAAwB;IACxB,0CAA0C;AAC9C;AACA;IACI,YAAY;AAChB;AACA;IACI,wBAAwB;IACxB,qBAAqB;AACzB;AACA;;IAEI,0CAA0C;IAC1C,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;;;IAGI,+BAA+B;AACnC;AACA;IACI,iCAAiC;AACrC;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".MuiPopover-root .MuiPopover-paper {\n    box-shadow: 0 4px 10px rgb(255 255 255/25%);\n}\n.MuiPopover-paper ul {\n    background-color: var(--darkGreen);\n    padding: 0;\n}\n.MuiPopover-paper ul li{\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n.MuiPopover-paper ul li {\n    padding-top: 10px;\n    padding-bottom: 10px;\n    color: var(--whiteLight);\n    border-bottom: 1px solid var(--whiteLight);\n}\n.MuiPopover-paper ul li:last-child{\n    border: none;\n}\n.MuiPopover-paper ul li  a{\n    color: var(--whiteLight);\n    text-decoration: none;\n}\n.MuiPopover-paper ul li:hover,\n.MuiPopover-paper ul li.Mui-selected{\n    background-color: rgba(145, 217, 184, 0.2);\n    color: var(--white);\n}\n.MuiPopover-paper ul li:hover a{\n    color: var(--white);\n}\n.select-option fieldset,\n\n.select-option .MuiInputBase-formControl:hover fieldset {\n    border-color: var(--whiteLight);\n}\n.select-option .MuiInputBase-colorPrimary.Mui-focused fieldset{\n    border-color: var(--primeryColor);\n}\n.select-option .MuiSelect-select {\n    color: var(--whiteLight);\n}\n.select-option .MuiInputBase-input {\n    color: var(--whiteLight);\n}\n.select-option .MuiInputBase-colorPrimary svg {\n    fill: var(--whiteLight);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
