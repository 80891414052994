import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import { basePath } from './Config';

const PrivateRoutes = ({ component: Component, roles, ...rest }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
  
    // if (!token) {
    //   // TODO: Uncomment line below when BE is working
    //   navigate(`${basePath}login`);
    // }

    // if (!user) {
    //   navigate(`${basePath}login`);
    // }

    // if (token && rest.path === `${basePath}login`) {
    //   return <Navigate to={`${basePath}` }/>;
    // }

    // if (roles && user && !roles.includes(user.role)) {
    //   navigate(`${basePath}unauthorized`);
    // }

  }, [user, navigate, rest.path, roles]);

  return (
    <Component />
  );
};

export default PrivateRoutes;
