import { Close, PushPin, PushPinOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import EventChart from "../../../../Components/EventChartComponent";
import { formatDurationUnits, parseDate } from "../../../../utils/helpers";
import { getPatientEventGraph } from "../../../../Api/AdapterApi";
import DownloadExcel from "./DownloadExcel";

const EventModal = ({
  patientName,
  open,
  onClose,
  event,
  patientId,
  updatePinnedEvents,
  defaultStart,
  defaultEnd,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isEventPinned, setEventPinned] = useState(false);

  // graph stuff
  const [data, setData] = useState(null);
  const [startTime, setStartTime] = useState(defaultStart);
  const [endTime, setEndTime] = useState(defaultEnd);

  const onCloseModal = () => {
    setCurrentTab(0);
    onClose();
  };

  const fetchProgressData = useCallback(async () => {
    if (!event?.graphs || !startTime || !endTime || startTime >= endTime)
      return;

    const currentGraph = event?.graphs[currentTab];

    const params = {
      patient_id: patientId,
      start: parseDate(startTime),
      end: parseDate(endTime),
      event_type_id: currentGraph?.stat,
    };
    const response = await getPatientEventGraph(params);

    if (response?.xs) {
      const parsed = parseData(currentGraph, response);
      setData(parsed);
    }
  }, [event?.graphs, startTime, endTime, currentTab, patientId]);

  useEffect(() => {
    fetchProgressData();
  }, [fetchProgressData]);

  useEffect(() => {
    setEventPinned(event.pinned);
  }, [event]);

  if (!event?.graphs) return;
  const currentGraph = event?.graphs[currentTab];

  const onChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleStartTimeChange = (date) => {
    toast.remove();
    if (endTime && date >= endTime) {
      toast.error("Start date should be before end date");
    }
    setStartTime(date);
  };

  const handleEndTimeChange = (date) => {
    toast.remove();
    if (startTime && startTime >= date) {
      toast.error("End date should be after start date");
    }
    setEndTime(date);
  };

  const handlePin = async () => {
    const success = await updatePinnedEvents(event.name);
    if (success) {
      event.pinned = !event.pinned;
      setEventPinned(event.pinned);
    }
  };

  const tabs = event?.graphs.map((graph, index) => (
    <Tab
      label={graph.name}
      key={index}
      sx={{
        color: "#91D9B9",
        "&.Mui-selected": {
          color: "#91D9B9",
        },
      }}
    />
  ));

  return (
    <Modal open={open} onClose={onCloseModal}>
      <Box sx={modalStyle}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            pt={1}
            px={2}
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid #598795"
          >
            <Tabs
              value={currentTab}
              onChange={onChangeTab}
              TabIndicatorProps={{
                children: <span className="MuiTabs-indicatorSpan" />,
              }}
              sx={{
                "& .MuiTabs-indicator": {
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                },
                "& .MuiTabs-indicatorSpan": {
                  width: "100%",
                  backgroundColor: "#91D9B9",
                },
              }}
            >
              {tabs}
            </Tabs>
            <Box>
              <IconButton onClick={handlePin}>
                {isEventPinned ? (
                  <PushPin sx={{ color: "#598795" }} />
                ) : (
                  <PushPinOutlined sx={{ color: "#598795" }} />
                )}
              </IconButton>
              <IconButton onClick={onCloseModal}>
                <Close sx={{ color: "#598795" }} />
              </IconButton>
            </Box>
          </Box>
          <Box p={2}>
            <Box display="flex" justifyContent="flex-end" gap={2} mb={3}>
              <Box className="select-option">
                <DesktopDatePicker
                  disableFuture
                  format="yyyy/MM/dd"
                  onChange={handleStartTimeChange}
                  value={startTime}
                  closeOnSelect={false}
                />
              </Box>
              <Box className="select-option">
                <DesktopDatePicker
                  disableFuture
                  format="yyyy/MM/dd"
                  onChange={handleEndTimeChange}
                  value={endTime}
                  closeOnSelect={false}
                />
              </Box>
              <DownloadExcel
                title={currentGraph?.name}
                data={data}
                patientName={patientName}
              />
            </Box>

            <Box gap={2}>
              <Typography fontSize="20px" align="center">
                {currentGraph?.name}
              </Typography>

              {data ? <EventChart data={data} /> : <Empty />}
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </Modal>
  );
};

export default EventModal;

const Empty = () => {
  return (
    <Typography align="center" component="h4" sx={{ py: 3 }}>
      Select dates
    </Typography>
  );
};

const parseData = (graph, data) => {
  let ys = data?.ys;
  let label = graph?.axis;
  let goal = graph?.goal;

  if (graph?.axis.endsWith("(Sec)")) {
    const [newYs, units] = formatDurationUnits(data?.ys);
    ys = newYs;
    label = graph?.axis.replace("(Sec)", `(${units})`);

    // goal units are currently always in minutes for duration graphs
    // so convert them to the correct number
    if (goal) {
      if (units === "secs") {
        goal *= 60;
      } else if (units === "hrs") {
        goal /= 60;
      }
      goal = goal.toFixed(2);
    }
  }

  const parsed = {
    label,
    goal: goal,
    start_treatment: data?.start_treatment,
    end_treatment: data?.end_treatment,
    xs: data?.xs || [],
    ys: ys || [],
  };

  return parsed;
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#042835",
  boxShadow: 24,
  color: "#91D9B9",
  width: "90%",
  maxWidth: "1200px",
  borderRadius: 2,
};
