import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Assets/Css/Responsive.css'
import App from './App';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);