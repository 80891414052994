import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddPhysician from "../Add physician/AddPhysician";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import { deleteApi } from "../../../Api/Api";
import { useSearch } from "../../../Context/SearchContext";
import toast from "react-hot-toast";
import { USER_ROUTE } from "../../../Api/Routes";

const PhysicianTable = ({
  physicianData,
  hospitalData,
  AddUpdatePhysician,
}) => {
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [physicianId, setPhysicianId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const { searchQuery } = useSearch();
  const [filteredData, setFilteredData] = useState(physicianData);

  useEffect(() => {
    if (searchQuery) {
      const filteredResults = physicianData.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchQuery)
        )
      );
      setFilteredData(filteredResults);
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, physicianData]);

  const displayData = searchQuery ? filteredData : physicianData;

  const handleOpen = (id) => {
    setEditValue(true);
    setPhysicianId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditValue(false);
    setPhysicianId(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (id) => {
    setPhysicianId(id);
    setEditValue(false);
    setOpenDialog(true);
  };

  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `${USER_ROUTE}/${physicianId}`;
    try {
      const responseData = await deleteApi(endpoint);
      if (responseData.data) {
        AddUpdatePhysician();
        toast.success("Physician Deleted");
      } else {
        toast.error("Physician Deleted Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box className="table-container">
      <TableContainer component={Paper}>
        <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Middle Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Hospital</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((physician, index) => (
              <StyledTableRow key={physician.id}>
                <StyledTableCell component="th" scope="row">
                  {physician.first_name}
                </StyledTableCell>
                <StyledTableCell>{physician.middle_name}</StyledTableCell>
                <StyledTableCell>{physician.last_name}</StyledTableCell>
                <StyledTableCell>{physician.email}</StyledTableCell>
                <StyledTableCell>{physician.phone_no}</StyledTableCell>
                <StyledTableCell>{physician.hospitalName}</StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" gap={2}>
                    <Button
                      variant="contained"
                      color="info"
                      className="btn-primary"
                      onClick={() => handleOpen(physician.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      className="btn-delete"
                      onClick={() => handleDelete(physician.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        content={`Confirm Physician Delete`}
        onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
      <AddPhysician
        open={open}
        handleClose={handleClose}
        editValue={editValue}
        physicianId={physicianId}
        hospitalData={hospitalData}
        AddUpdatePhysician={AddUpdatePhysician}
        physicianData={physicianData}
      />
    </Box>
  );
};

export default PhysicianTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    // fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));