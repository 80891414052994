import { Box, Typography } from '@mui/material'
import Link from "@mui/material/Link"
import React from 'react'
import "./Login.css"
import "../../Assets/Css/Responsive.css"
import loginLeft from "./../../Assets/Images/Login/login-left.png"
import logo from "./../../Assets/Images/Login/logo.svg"
import Input from '../../Components/InputComponent/Input'
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent"
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { postLogin } from '../../Api/Service'
import toast, { Toaster } from 'react-hot-toast'
import { basePath } from '../../routes/Config'

const ForgotPassword = () => {
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()


    const onSubmit = async (data, event) => {
        event.preventDefault();
        const endpoint = '/forget_password';
        try {
            const responseData = await postLogin(endpoint, data);
            if (responseData.statusCode === 200) {
                reset();
                navigate(`${basePath}email-link`)
            }
            else {
                toast.error(responseData.error);
            }
        } catch (error) {
            toast.error("Login Error - User is not authenticated");
        }
    };

    return (
        <Box className="login-container">
            <Toaster position="top-center" reverseOrder={false} />
            <Box className="login-main">
                <Box className="left-section">
                    <Box className="img-container">
                        <Box className="text-container">
                            <Typography component="h4" className='heading'>Easy to observe </Typography>
                            <Typography component="h4" className='heading'>your daily sleep </Typography>
                            <Typography component="h4" className='heading'>routine.</Typography>
                        </Box>
                        <img src={loginLeft} alt='' />
                    </Box>
                </Box>
                <Box className="right-section" component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Box className="logo-box">
                        <Link href="#" >
                            <img src={logo} alt='Logo' />
                        </Link>
                    </Box>
                    <Box className="login-details">
                        <Box className="heading--box">
                            <Typography component="h2" className='text-primery heading'>Forgot password</Typography>
                            <Typography component="p" className='text-white'>No worries, we'll send you reset instructions.</Typography>
                        </Box>
                    </Box>
                    <Box className="form-group"  >
                        <Input
                            label="Email"
                            placeholder="ex. percy@gamil.com"
                            type="email"
                            name="email"
                            requiredFeild="requiredFeild"
                            register={register}
                            validationRules={{
                                required: 'Email is required',
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: 'Invalid email format. Please enter a valid email address.',
                                  },
                            }}
                        />
                        {errors.email && <p className="error-message">{errors.email.message}</p>}
                        {/* <Input label="Email or phone" placeholder="ex. precy@gamil.com" type="email" /> */}
                    </Box>

                    <Box className="form-group">
                        <ButtonComponent type="submit" buttonText="Send" buttonVariant="contained" />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ForgotPassword