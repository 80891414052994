import React, { useEffect, useState } from "react";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tab,
  Tabs,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useParams } from "react-router-dom";
import { GetApiParam } from "../../Api/Api";
import SetGoalComponent from "../SetGoalComponent/SetGoalComponent";
import { AddPatients } from "./components";

import "../../Components/SelectComponent/SelectComponent.css"

import {
  PatientDetailsCard,
  AddTreatments,
  ProgressCard,
  TimelineCard,
  EventsCard,
} from "./components";
import { PATIENT_ROUTE, USER_ROUTE } from "../../Api/Routes";

const PatientsDetails = () => {
  const { id } = useParams();

  const [patientDetails, setPatientDetails] = useState([]);
  const [physicianData, setPhysicianData] = useState([]);
  const [openEditPatientDetails, setOpenEditPatientDetails] = useState(false);
  const [openEditTreatments, setOpenEditTreatments] = useState(false);
  const [editValueTreatments, setEditValueTreatments] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const fetchPatientDetails = async () => {
    try {
      const patientResponse = await GetApiParam(`${PATIENT_ROUTE}/${id}`);
      const physicianResponse = await GetApiParam(USER_ROUTE, { role: "Physician", id: patientResponse?.data?.physician_id });

      const patient = patientResponse.data;
      const physicians = physicianResponse.data.users;

      const physician = physicians.find((user) => user.id === patient.physician_id);
      patient.physician_name = `${physician?.first_name} ${physician?.last_name}`;

      setPatientDetails(patient);
      setPhysicianData(physicians);
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  useEffect(() => {
    fetchPatientDetails();
  }, [id]);

  const handleOpenEditPatient = () => {
    setOpenEditPatientDetails(true);
  }

  const handleCloseEditPatient = () => {
    setOpenEditPatientDetails(false);
  }

  const handleOpenAddTreatment = () => {
    setOpenEditTreatments(true);
    setEditValueTreatments(false);
  };

  const handleOpenEditTreatment = () => {
    setOpenEditTreatments(true);
    setEditValueTreatments(true);
  };

  const handleCloseEditTreatment = () => {
    setOpenEditTreatments(false);
  };

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  return (
    <Box>
      <Box className="my-patients-topbar" mt={3}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <Tabs
            className="title"
            component="h4"
            value={currentTabIndex}
            onChange={handleTabChange}
            TabIndicatorProps={{
              sx: { backgroundColor: "#91D9B9" },
            }}
            sx={{
              ".MuiButtonBase-root": {
                color: "#91D9B9 !important",
              },
              ".Mui-disabled": {
                color: "#9e9e9e !important",
              },
            }}
          >
            <Tab label="Overview" />
            <Tab label="Goal Setup" />
            {/* <Tab label="Calibration" disabled={!patientDetails?.device_name} /> */}
          </Tabs>
          {
            currentTabIndex === 0 && <Button
              variant="contained"
              color="info"
              className="btn-primary"
              sx={{ fontWeight: 'bold' }}
              onClick={handleOpenEditPatient}
            >
              Edit Patient
            </Button>
          }
        </Box>
        {/* Edit patient modal */}
        <AddPatients
          open={openEditPatientDetails}
          handleClose={handleCloseEditPatient}
          editValue={true}
          patientId={patientDetails?.id}
          AddUpdatePatient={fetchPatientDetails}
          physicianData={physicianData || []}
        />
      </Box>
      {currentTabIndex === 0 && (
        <>
          {/* Patient Card */}
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={5} lg={4}>
                <PatientDetailsCard
                  {...patientDetails}
                  controller={patientDetails?.device_name}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={7} lg={8}>
                <ProgressCard patientId={id} />
              </Grid>
            </Grid>
          </Box>
          {/* Events Section */}
          <EventsCard patientId={id} pinnedEventsData={patientDetails?.pinned_events} />
          {/* Timeline */}
          <TimelineCard patientId={id} />
          {/* Treatment History */}
          {/* <Box className="my-patients-topbar" mt={3}>
            <Box>
              <Typography className="title" component="h4">
                Treatment History
              </Typography>
            </Box>
            <Box className="right btn-font-small">
              <ButtonComponent
                buttonText="Add Treatment"
                buttonVariant="contained"
                startIcon={<AddIcon />}
                buttonStyle={{ borderRadius: "30px" }}
                onClick={handleOpenAddTreatment}
              />
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell> Prescription </StyledTableCell>
                  <StyledTableCell> Start Date </StyledTableCell>
                  <StyledTableCell> End Date </StyledTableCell>
                  <StyledTableCell> Notes </StyledTableCell>
                  <StyledTableCell> {""} </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayData.map((treatment, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {treatment.prescription}
                    </StyledTableCell>
                    <StyledTableCell>{treatment.start_date}</StyledTableCell>
                    <StyledTableCell>{treatment.end_date}</StyledTableCell>
                    <StyledTableCell> {treatment.notes} </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "right" }}>
                      <Button
                        variant="contained"
                        color="info"
                        className="btn-primary"
                        sx={{ width: "45%" }}
                        onClick={handleOpenEditTreatment}
                      >
                        Edit
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AddTreatments
            open={openEditTreatments}
            handleClose={handleCloseEditTreatment}
            editValue={editValueTreatments}
          /> */}
        </>
      )}

      {/* Goals Setup Tab */}
      {currentTabIndex === 1 && <SetGoalComponent patientId={patientDetails?.id} goals={patientDetails?.goals} mutate={fetchPatientDetails} />}
    </Box>
  );
};

export default PatientsDetails;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const displayData = [
  {
    prescription: "Clonindine 0.50 mg",
    start_date: "2024-08-09",
    end_date: "2024-08-11",
    notes: "",
  },
  {
    prescription: "Clonindine 0.50 mg",
    start_date: "2024-08-11",
    end_date: "2024-08-14",
    notes: "",
  },
  {
    prescription: "Melatonin 5 mg",
    start_date: "2024-08-14",
    end_date: "In Effect",
    notes: "",
  },
];