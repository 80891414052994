import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddUser from "../Add user/AddUser";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import { deleteApi } from "../../../Api/Api";
import { useSearch } from "../../../Context/SearchContext";
import toast from "react-hot-toast";
import { USER_ROUTE } from "../../../Api/Routes";
import { getCurrentUserId } from "../../../utils/helpers";

const UserTable = ({ userData, AddUpdateUser }) => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const { searchQuery } = useSearch();
  const [filteredData, setFilteredData] = useState(userData);

  useEffect(() => {
    if (searchQuery) {
      const filteredResults = userData.filter((item) =>
        Object.values(item).some((value) => String(value).includes(searchQuery))
      );
      setFilteredData(filteredResults);
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, userData]);

  const displayData = searchQuery ? filteredData : userData;

  const handleOpen = (id) => {
    setUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUserId(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (id) => {
    setUserId(id);
    setOpenDialog(true);
  };

  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `${USER_ROUTE}/${userId}`;

    try {
      const responseData = await deleteApi(endpoint);
      if (responseData.data) {
        AddUpdateUser();
        toast.success("User Deleted");
      } else {
        toast.error("User Deleted Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box className="table-container">
      <TableContainer component={Paper}>
        <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Middle Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((user, index) => (
              <StyledTableRow key={user.id}>
                <StyledTableCell component="th" scope="row">
                  {user.first_name}
                </StyledTableCell>
                <StyledTableCell>{user.middle_name}</StyledTableCell>
                <StyledTableCell>{user.last_name}</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell>{user.phone_no}</StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" gap={2}>
                    <Button
                      variant="contained"
                      color="info"
                      className="btn-primary"
                      onClick={() => handleOpen(user.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      className="btn-delete"
                      disabled={user.id === getCurrentUserId()}
                      onClick={() => handleDelete(user.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        content={"Confirm Delete User"}
        onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
      <AddUser
        open={open}
        handleClose={handleClose}
        userId={userId}
        AddUpdateUser={AddUpdateUser}
        selectedUser={userData.find((user) => user.id === userId)}
      />
    </Box>
  );
};

export default UserTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
