import { Box, Popper, Typography } from "@mui/material";
import TimelineChart from "../../../../Components/TimelineChartComponent";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useCallback, useEffect, useState } from "react";
import { parseDateTime } from "../../../../utils/helpers";
import { getPatientTimeline } from "../../../../Api/AdapterApi";
import toast from "react-hot-toast";
import { Info } from "@mui/icons-material";
import TimelinePopper from "./TimelinePopper";

const TimelineCard = ({ patientId }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [data, setData] = useState();

  const fetchTimelineData = useCallback(async () => {
    if (!patientId || !startTime || !endTime || startTime >= endTime) return;

    try {
      const params = {
        patient_id: patientId,
        start: parseDateTime(startTime),
        end: parseDateTime(endTime),
      };
      const response = await getPatientTimeline(params);

      if (response?.minutes) {
        setData(response);
      }
    } catch (error) {
      console.log("Error fetching timeline data:", error);
    }
  }, [patientId, startTime, endTime]);

  useEffect(() => {
    fetchTimelineData();
  }, [fetchTimelineData]);

  const handleStartTimeChange = (date) => {
    toast.remove();
    if (endTime && date >= endTime) {
      toast.error("Start date should be before end date");
    }
    setStartTime(date);
  };

  const handleEndTimeChange = (date) => {
    toast.remove();
    if (startTime && startTime >= date) {
      toast.error("End date should be after start date");
    }
    setEndTime(date);
  };

  const handleInfoClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const anchorId = open ? "simple-popper" : undefined;

  return (
    <Box id="timeline-container">
      <Box className="my-patients-topbar" mt={3}>
        <Typography className="title" component="h4">
          Timeline{" "}
          {data?.events && data?.events.length > 0 && (
            <Info
              aria-describedby={anchorId}
              onClick={handleInfoClick}
              sx={{ cursor: "pointer" }}
            />
          )}
        </Typography>

        <Popper
          id={anchorId}
          open={open}
          anchorEl={anchorEl}
          placement="top-start"
        >
          <TimelinePopper events={data?.events} />
        </Popper>
        <Box display="flex" gap="20px">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box className="select-option">
              <DesktopDateTimePicker
                // disableFuture
                format="MM-dd-yy HH:mm"
                ampm={false}
                onChange={handleStartTimeChange}
              />
            </Box>
            <Box className="select-option">
              <DesktopDateTimePicker
                onChange={handleEndTimeChange}
                // disableFuture
                format="MM-dd-yy HH:mm"
                ampm={false}
              />
            </Box>
          </LocalizationProvider>
        </Box>
      </Box>

      {!data ? (
        <Box
          display="flex"
          alignItems="center"
          bgcolor={"#042835"}
          justifyContent="center"
          p={5}
        >
          <Typography component="h4" className="text-primery" align="center">
            Select dates
          </Typography>
        </Box>
      ) : (
        <TimelineChart data={data}/>
      )}
    </Box>
  );
};

export default TimelineCard;
