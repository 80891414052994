import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import ExcellentExport from "excellentexport";
import React, { useMemo } from "react";

const DownloadExcel = ({ data, title, patientName }) => {
  const createTable = useMemo(() => {
    if (!data?.xs) return null;

    const tableContent = data.xs.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item}</td>
          <td>{data.ys[index]}</td>
        </tr>
      );
    });

    return (
      <table id="datatable" style={{ display: "none" }}>
        <tr>
          <td>Date</td>
          <td>{data?.label || "Data"}</td>
        </tr>
        {tableContent}
      </table>
    );
  }, [data?.label, data?.xs, data?.ys]);

  const onDownload = () => {
    return ExcellentExport.convert(
      {
        anchor: this,
        filename: `${patientName}-${title}`,
        format: "xlsx",
        openAsDownload: true,
      },
      [{ name: "Sheet 1", from: { table: "datatable" } }]
    );
  };

  if (!data?.xs || !data?.ys) return;

  return (
    <>
      {createTable}
      <Button
        className="btn-primary"
        onClick={onDownload}
        sx={{ fontWeight: "bold" }}
      >
        <Download sx={{ color: "#052835" }} />
      </Button>
    </>
  );
};

export default DownloadExcel;
