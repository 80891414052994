// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-box{
    width: 100%;
}
.input-wrap{
    border: 1px solid var(--green);
    border-radius: 5px;
}
.input-box label.Mui-focused,
.input-box .input-label, .input-box .input-label:focus{
    color: var(--white);
    font-size: 16px;
    position: static;   
    transform: none;
    text-align: left;
    margin-bottom: 2px;
}
.input-box input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-transition: background-color 0s 600000s, color 0s 600000s !important;
    transition: background-color 0s 600000s, color 0s 600000s !important;
}
.input-box .input-label span{
    color: red;
}
.input-box input {
    background: #062A37;
    border-color: var(--whiteLight);
    color: var(--white);
}

.input-box .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
.input-box .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primeryColor);
}

.input-box .MuiFormControl-root fieldset {
    border-color: var(--whiteLight);
}
.input-box .MuiFormControl-root .Mui-disabled + fieldset {
    border-color: var(--whiteLight) !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/InputComponent/Input.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,8BAA8B;IAC9B,kBAAkB;AACtB;AACA;;IAEI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;AACA;;IAEI,4EAAoE;IAApE,oEAAoE;AACxE;AACA;IACI,UAAU;AACd;AACA;IACI,mBAAmB;IACnB,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;;IAEI,iCAAiC;AACrC;;AAEA;IACI,+BAA+B;AACnC;AACA;IACI,0CAA0C;AAC9C","sourcesContent":[".input-box{\n    width: 100%;\n}\n.input-wrap{\n    border: 1px solid var(--green);\n    border-radius: 5px;\n}\n.input-box label.Mui-focused,\n.input-box .input-label, .input-box .input-label:focus{\n    color: var(--white);\n    font-size: 16px;\n    position: static;   \n    transform: none;\n    text-align: left;\n    margin-bottom: 2px;\n}\n.input-box input:-webkit-autofill,\ninput:-webkit-autofill:focus {\n    transition: background-color 0s 600000s, color 0s 600000s !important;\n}\n.input-box .input-label span{\n    color: red;\n}\n.input-box input {\n    background: #062A37;\n    border-color: var(--whiteLight);\n    color: var(--white);\n}\n\n.input-box .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,\n.input-box .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {\n    border-color: var(--primeryColor);\n}\n\n.input-box .MuiFormControl-root fieldset {\n    border-color: var(--whiteLight);\n}\n.input-box .MuiFormControl-root .Mui-disabled + fieldset {\n    border-color: var(--whiteLight) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
