import { Box, Typography } from '@mui/material'

import React from 'react'
import "./Login.css"
import "../../Assets/Css/Responsive.css"
import loginLeft from "./../../Assets/Images/Login/login-left.png"
import logo from "./../../Assets/Images/Login/logo.svg"
import { Link } from 'react-router-dom'
import { basePath } from '../../routes/Config'

const CheckEmail = () => {
    return (
        <Box className="login-container">
            <Box className="login-main">
                <Box className="left-section">
                    <Box className="img-container">
                        <Box className="text-container">
                            <Typography component="h4" className='heading'>Easy to observe </Typography>
                            <Typography component="h4" className='heading'>your daily sleep </Typography>
                            <Typography component="h4" className='heading'>routine.</Typography>
                        </Box>
                        <img src={loginLeft} alt='' />
                    </Box>
                </Box>
                <Box className="right-section" >
                    <Box className="logo-box">
                        <Link href="#" >
                            <img src={logo} alt='Logo' />
                        </Link>
                    </Box>
                    <Box className="login-details">
                        <Box className="heading--box">
                            <Typography component="h2" className='text-primery heading'>Email Sent</Typography>
                            <Typography component="p" className='text-white'>Password reset link sent to your email address. Please check your email inbox.</Typography>
                        </Box>
                    </Box>

                    <Box className="back-btn--box text--right">
                        <Link to={`${basePath}login`} className="text-primery no-underline line">
                            &#60; Back to log in
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CheckEmail