import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { format, parseISO } from "date-fns";

const ControllersTable = ({ controllerData }) => {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table style={{ borderCollapse: "collapse", minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Device Name</StyledTableCell>
              <StyledTableCell>Mac Address</StyledTableCell>
              <StyledTableCell>Assigned Patient</StyledTableCell>
              <StyledTableCell>Last Message Time</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {controllerData &&
              controllerData.map((controller) => (
                <StyledTableRow key={controller.id}>
                  <StyledTableCell component="th" scope="row">
                    {controller.name}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {controller.mac_address}
                  </StyledTableCell>
                  <StyledTableCell>
                    {controller.patient_name || <i>Unassigned</i>}
                  </StyledTableCell>
                  <StyledTableCell>
                    {controller.last_message_time
                      ? format(
                          parseISO(controller.last_message_time),
                          "MM-dd-yy HH:mm"
                        )
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {controller.is_online ? (
                      <Chip label="Online" color="success" />
                    ) : (
                      <Chip label="Offline" color="error" />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ControllersTable;

// Define the styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
