import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Input from "../../Components/InputComponent/Input";
import { postApi } from "../../Api/Api";

const ChangePassword = (props) => {
  const { handleClose, open } = props;
  const [loading, setLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // localStorage.clear();
  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user?.id;

  const style = {
    position: "absolute",
    top: "50%",
    left: 15,
    right: 15,
    transform: "translateY(-50%)",
    maxWidth: 1000,
    color: "#ffffff",
    boxShadow: 24,
    margin: "1rem auto",
    maxHeight: "100%",
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (e) => {
    e.preventDefault();
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const passwordMatchValidation = (value) => {
    const password = watch("password"); // Get the current value of the password field
    return value === password || "Passwords do not match";
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const endpoint = `/users/change_password`;
    data.userId = userID;
    try {
      setLoading(true);
      const responseData = await postApi(endpoint, data);
      if (responseData.data.message === "Password successfully updated") {
        handleClose();
        reset();
        toast.success(responseData.data.message);
        setLoading(false);
      } else {
        toast.error(responseData.data.error);
        setLoading(false);
      }
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="modal-shadow">
            <Box className="modal-header">
              <Typography
                id="modal-modal-title"
                className="text-primery"
                variant="h4"
                component="h4"
              >
                Change Password
              </Typography>
              <Button onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </Box>
            <Box
              className="modal-body"
              id="modal-modal-description"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="pass-btn-group">
                        <Input
                          label="New Password"
                          placeholder="Enter your Password"
                          requiredField="requiredField"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          register={register}
                          validationRules={{
                            required: "New Password is required",
                            pattern: {
                              value:
                                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
                              message:
                                "New Password should be of 8 in length and consist of at least 1 special character, 1 upper case character and 1 number.",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="password"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors.password && (
                          <p className="error-message">
                            {errors.password.message}
                          </p>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Box className="pass-btn-group">
                        <Input
                          label="Confirm Password"
                          placeholder="Enter your Password"
                          requiredField="requiredField"
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          register={register}
                          validationRules={{
                            required: "Confirm Password is required",
                            pattern: {
                              value:
                                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
                              message:
                                "Confirm Password should be of 8 in length and consist of at least 1 special character, 1 upper case character and 1 number.",
                            },
                            validate: passwordMatchValidation,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="password"
                                  onClick={handleShowConfirmPassword}
                                  onMouseDown={handleMouseDownConfirmPassword}
                                >
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors.confirmPassword && (
                          <p className="error-message">
                            {errors.confirmPassword.message}
                          </p>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-wrapper">
                        <LoadingButton
                          type="submit"
                          className="loading-btn"
                          variant="contained"
                          loading={loading}
                          onClick={handleSubmit}
                        >
                          Submit
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChangePassword;
