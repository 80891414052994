import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import React, { useState } from "react";
import "./Login.css";
import "../../Assets/Css/Responsive.css";
import loginLeft from "./../../Assets/Images/Login/login-left.png";
import logo from "./../../Assets/Images/Login/logo.svg";
import Input from "../../Components/InputComponent/Input";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import { useForm } from "react-hook-form";
import { postLogin } from "../../Api/Service";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast, { Toaster } from "react-hot-toast";
import { basePath } from "../../routes/Config";
import { LOGIN_ROUTE } from "../../Api/Routes";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, event) => {
    event.preventDefault();

    try {
      const response = await postLogin(LOGIN_ROUTE, data);
      const responseData = response?.data;

      if (responseData?.success) {
        const userData = {
          first_name: responseData?.first_name,
          last_name: responseData?.last_name,
          role: responseData?.role,
          id: responseData?.id,
          title: responseData?.title,
        };

        login(null, userData);
        localStorage.setItem("user", JSON.stringify(userData));
        window.history.replaceState({}, "", basePath);
        navigate(basePath, { state: { from: `${basePath}login` } });
      } else {
        toast.error("Invalid User Credential");
      }
    } catch (error) {
      console.log("error", error);
      if (error?.response) {
        toast.error(error?.response?.data.error);
      }
    }
  };

  return (
    <Box className="login-container">
      <Toaster position="top-center" reverseOrder={false} />
      <Box className="login-main">
        <Box className="left-section">
          <Box className="img-container">
            <Box className="text-container">
              <Typography component="h4" className="heading">
                Easy to observe{" "}
              </Typography>
              <Typography component="h4" className="heading">
                your daily sleep{" "}
              </Typography>
              <Typography component="h4" className="heading">
                routine.
              </Typography>
            </Box>
            <img src={loginLeft} alt="" />
          </Box>
        </Box>
        <Box className="right-section">
          <Box className="logo-box">
            <Link href="#">
              <img src={logo} alt="Logo" />
            </Link>
          </Box>
          <Box className="login-details">
            <Box className="heading--box">
              <Typography component="h2" className="text-primery heading">
                Hello again!
              </Typography>
              <Typography component="p" className="text-white">
                Welcome, so good to see you! Log in below.
              </Typography>
            </Box>
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Box className="form-group">
              <Input
                label="Email"
                placeholder="Enter your Email"
                type="email"
                name="email"
                register={register}
                requiredField="requiredField"
                validationRules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message:
                      "Invalid email format. Please enter a valid email address.",
                  },
                }}
              />
              {errors.email && (
                <p className="error-message">{errors.email.message}</p>
              )}
            </Box>

            <Box className="form-group">
              <Box className="pass-btn-group">
                <Input
                  label="Password"
                  placeholder="Enter your Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  requiredField="requiredField"
                  register={register}
                  validationRules={{
                    required: "Password is required.",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="password"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {errors.password && (
                <p className="error-message">{errors.password.message}</p>
              )}
            </Box>

            <Box className="form-group">
              <ButtonComponent
                buttonText="Login"
                buttonVariant="contained"
                customStyle={{
                  marginTop: "10px",
                  backgroundColor: "green",
                  color: "white",
                }}
                type="submit"
              />
            </Box>
          </Box>
          <Box className="text--right">
            <Link
              to={`${basePath}forget-password`}
              className="text-white no-underline border-underline"
            >
              Forgot password ?
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
