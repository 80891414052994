import React from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import "./AddPatients.css";

import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import Input from "../../../../Components/InputComponent/Input";
import ButtonComponent from "../../../../Components/ButtonComponent/ButtonComponent";

const AddTreatments = (props) => {
  const { handleClose, open, editValue } = props;
  // const [value, setValue] = useState()
  const style = {
    position: "absolute",
    top: "50%",
    left: 15,
    right: 15,
    transform: "translateY(-50%)",
    maxWidth: 1000,
    color: "#ffffff",
    boxShadow: 24,
    margin: "1rem auto",
    maxHeight: "100%",
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, event) => {
    event.preventDefault();
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="modal-shadow">
            <Box className="modal-header">
              {editValue ? (
                <Typography
                  id="modal-modal-title"
                  className="text-primery"
                  variant="h4"
                  component="h4"
                >
                  Edit Treatment
                </Typography>
              ) : (
                <Typography
                  id="modal-modal-title"
                  className="text-primery"
                  variant="h4"
                  component="h4"
                >
                  Add Treatment
                </Typography>
              )}
              <Button onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </Box>
            <Box
              className="modal-body"
              id="modal-modal-description"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Input
                        label="Prescription"
                        placeholder="Clonindine"
                        type="text"
                        name="prescription"
                        requiredField="requiredField"
                        register={register}
                        validationRules={{
                          required: 'Prescription" is required',
                        }}
                      />
                      {errors.symptom && (
                        <p className="error-message">
                          {errors.symptom.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Input
                        label="Notes"
                        placeholder=""
                        type="text"
                        name="notes"
                        requiredField="requiredField"
                        register={register}
                        // validationRules={{
                        //   required: 'Prescription is required',
                        // }}
                      />
                      {errors.prescription && (
                        <p className="error-message">
                          {errors.prescription.message}
                        </p>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-center">
                        <ButtonComponent
                          type="submit"
                          buttonText="Add Treatment"
                          buttonVariant="contained"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddTreatments;
