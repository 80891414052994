import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import "./Profile.css";
import { useForm } from "react-hook-form";
import Input from "../../Components/InputComponent/Input";
import { updateApi } from "../../Api/Api";
import toast from "react-hot-toast";

const Profile = (props) => {
  const { handleClose, open } = props;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const userDetails = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setValue("title", userDetails.title);
        setValue("first_name", userDetails.first_name);
        setValue("middle_name", userDetails.middle_name);
        setValue("last_name", userDetails.last_name);
        setValue("phone_no", userDetails.phone_no);
        setValue("email", userDetails.email);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [userDetails, setValue]);

  const NameValidation = (value) => {
    return !value.trim() || value.trim().length === 0
      ? "First Name field is required"
      : true;
  };

  const onSubmit = async (formData, event) => {
    event.preventDefault();
    const firstName = formData.first_name.trim();
    const lastName = formData.last_name.trim();
    const endpoint = `/users/${userDetails.id}`;

    const updatedData = {
      ...formData,
      id: userDetails.id,
      first_name: firstName,
      last_name: lastName,
    };

    try {
      const responseData = await updateApi(endpoint, {
        ...updatedData,
        id: userDetails.id,
      });
      if (responseData.data) {
        const updatedUserDetails = { ...userDetails, ...updatedData };
        localStorage.setItem("user", JSON.stringify(updatedUserDetails));
        reset();
        handleClose();
        toast.success("Profile Updated");
      } else {
        toast.error("something went wrong");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="modal-shadow">
            <Box className="modal-header">
              <Typography
                id="modal-modal-title"
                className="text-primery"
                variant="h4"
                component="h4"
              >
                Profile Update
              </Typography>
              <Button onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </Box>
            <Box
              className="modal-body"
              id="modal-modal-description"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label={"First Name"}
                        placeholder="Lindsey"
                        type="text"
                        name="first_name"
                        disabled
                        register={register}
                        validationRules={{
                          required: "First Name is required",
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message:
                              "First Name should contain only letters and spaces",
                          },
                          validate: NameValidation,
                        }}
                      />
                      {errors.first_name && (
                        <p className="error-message">
                          {errors.first_name.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Last Name"
                        placeholder="Thomas"
                        type="text"
                        name="last_name"
                        register={register}
                        disabled
                        validationRules={{
                          required: "Last Name is required",
                          validate: NameValidation,
                          pattern: {
                            value: /^[a-zA-Z\s]+$/,
                            message:
                              "First Name should contain only letters and spaces",
                          },
                        }}
                      />
                      {errors.last_name && (
                        <p className="error-message">
                          {errors.last_name.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Phone Number"
                        placeholder="963214587"
                        type="text"
                        name="phone_no"
                        register={register}
                        validationRules={{
                          required: "Phone Number is required",
                        }}
                        disabled
                      />
                      {errors.phone_no && (
                        <p className="error-message">
                          {errors.phone_no.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="apollo@gmail.com"
                        type="email"
                        name="email_id"
                        disabled
                        register={register}
                      />
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-center">
                        <ButtonComponent type="submit" buttonText="Profile Update" buttonVariant="contained" />
                      </Box>
                    </Box>
                  </Grid> */}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Profile;

const style = {
  position: "absolute",
  top: "50%",
  left: 15,
  right: 15,
  transform: "translateY(-50%)",
  maxWidth: 1000,
  color: "#ffffff",
  boxShadow: 24,
  margin: "1rem auto",
  maxHeight: "100%",
};
