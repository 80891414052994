import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import "./AddPhysician.css";
import { Controller, useForm } from "react-hook-form";
import Input from "../../../Components/InputComponent/Input";
import ButtonComponent from "../../../Components/ButtonComponent/ButtonComponent";
import { postApi, updateApi } from "../../../Api/Api";
import toast from "react-hot-toast";
import { USER_ROUTE } from "../../../Api/Routes";

const AddPhysician = (props) => {
  const {
    handleClose,
    open,
    editValue,
    physicianId,
    AddUpdatePhysician,
    hospitalData,
    physicianData,
  } = props;
  const [hospitalDetails, setHospitalDetails] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (editValue) {
      const fetchPhysicianDetails = async () => {
        try {
          // get physician from physicianData
          const physician = physicianData.find(
            (physician) => physician.id === physicianId
          );

          const physicianDetails = physician;
          setValue("title", physicianDetails.title);
          setValue("first_name", physicianDetails.first_name);
          setValue("middle_name", physicianDetails.middle_name);
          setValue("last_name", physicianDetails.last_name);
          setValue("phone_no", physicianDetails.phone_no);
          setValue("email", physicianDetails.email);
          setValue("hospital_id", physicianDetails.hospital_id);

          setHospitalDetails(
            hospitalData.find(
              (hospital) => hospital.id === physicianDetails.hospital_id
            )
          );
        } catch (error) {
          console.error("Error fetching physician details:", error);
        }
      };

      fetchPhysicianDetails();
    }
  }, [editValue, physicianId, hospitalData, setValue, physicianData]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const endpoint = editValue ? `${USER_ROUTE}/${physicianId}` : USER_ROUTE;
    const apiFunction = editValue ? updateApi : postApi;

    data.role = "Physician";

    try {
      const responseData = await apiFunction(
        endpoint,
        editValue ? { ...data, id: physicianId } : data
      );

      if (responseData?.data) {
        toast.success(editValue ? "Physician Updated" : "Physician Added");
        reset();
        handleClose();
        AddUpdatePhysician();
      } else {
        toast.error(
          editValue ? "Physician Updated Failed" : "Physician Added Failed"
        );
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className="modal-shadow">
            <Box className="modal-header">
              <Typography
                id="modal-modal-title"
                className="text-primery"
                variant="h4"
                component="h4"
              >
                Add Physician
              </Typography>
              <Button onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    opacity="0.2"
                    d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z"
                    fill="white"
                  />
                </svg>
              </Button>
            </Box>
            <Box
              className="modal-body"
              id="modal-modal-description"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Title"
                        placeholder="Mr / Ms"
                        type="text"
                        name="title"
                        register={register}
                        defaultValue=""
                      />
                      {errors.title && (
                        <p className="error-message">{errors.title.message}</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="First Name"
                        placeholder="Lindsey"
                        type="text"
                        name="first_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: "First Name is required",
                        }}
                      />
                      {errors.first_name && (
                        <p className="error-message">
                          {errors.first_name.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Middle Name"
                        placeholder="Daniel"
                        type="text"
                        name="middle_name"
                        register={register}
                        defaultValue=""
                      />
                      {errors.middle_name && (
                        <p className="error-message">
                          {errors.middle_name.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Last Name"
                        placeholder="Thomas"
                        type="text"
                        name="last_name"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: "Last Name is required",
                        }}
                      />
                      {errors.last_name && (
                        <p className="error-message">
                          {errors.last_name.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Phone Number"
                        placeholder="963214587"
                        type="text"
                        name="phone_no"
                        register={register}
                        defaultValue=""
                      />
                      {errors.phone_no && (
                        <p className="error-message">
                          {errors.phone_no.message}
                        </p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className="form-group">
                      <Input
                        label="Email"
                        placeholder="apollo@gmail.com"
                        type="email"
                        name="email"
                        requiredFeild="requiredFeild"
                        register={register}
                        validationRules={{
                          required: "Email is required",
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Please enter a valid email address",
                          },
                        }}
                      />
                      {errors.email && (
                        <p className="error-message">{errors.email.message}</p>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box className="form-group">
                      <Box className="select-opation--wrapper">
                        <FormControl variant="standard">
                          <InputLabel shrink className="input-label">
                            Hospital
                          </InputLabel>
                          <Controller
                            name="hospital_id"
                            control={control}
                            requiredField="requiredField"
                            validationRules={{
                              required: "Hospital is required",
                            }}
                            defaultValue={
                              hospitalDetails ? hospitalDetails.id : ""
                            }
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                id="combo-box-demo"
                                options={hospitalData}
                                getOptionLabel={(hospital) =>
                                  hospital.name || ""
                                }
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    placeholder={
                                      hospitalDetails
                                        ? hospitalDetails.name
                                        : ""
                                    }
                                    {...params}
                                  />
                                )}
                                onChange={(_, value) =>
                                  setValue("hospital_id", value ? value.id : "")
                                }
                                value={
                                  hospitalData.find(
                                    (hospital) =>
                                      hospital.id === watch("hospital_id")
                                  ) || null
                                }
                                key={hospitalDetails ? hospitalDetails.id : ""}
                                defaultValue={
                                  hospitalDetails ? hospitalDetails.id : ""
                                }
                              />
                            )}
                          />
                          {errors.hospital_id && (
                            <p className="error-message">
                              {errors.hospital_id.message}
                            </p>
                          )}
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-group">
                      <Box className="btn-center">
                        <ButtonComponent
                          type="submit"
                          buttonText={
                            editValue ? "Update Physician" : "Add Physician"
                          }
                          buttonVariant="contained"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: 15,
  right: 15,
  transform: "translateY(-50%)",
  maxWidth: 1000,
  color: "#ffffff",
  boxShadow: 24,
  margin: "1rem auto",
  maxHeight: "100%",
};

export default AddPhysician;
