import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Autocomplete,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import "./SetGoalComponet.css";
import { GetApiParam, postApi, deleteApi } from "../../Api/Api";
import { PATIENT_ROUTE } from "../../Api/Routes";
import { Cancel } from "@mui/icons-material";

const SetGoalComponent = (props) => {
  const { patientId, mutate } = props;
  const [goals, setGoals] = useState([...props?.goals || [], { stat: "", op: "", value: "" }]);
  const [goalOptions, setGoalOptions] = useState([]);

  const fetchPatientData = async () => {
    const patientResponse = await GetApiParam(`${PATIENT_ROUTE}/${patientId}`);
    setGoals([...patientResponse.data.goals || [], { stat: "", op: "", value: "" }]);

  };

  const handleAddGoal = async (goal) => {
    try {
      const body = {
        ...goal,
        value: parseInt(goal.value) || 0,
      };
      await postApi(`${PATIENT_ROUTE}/${patientId}/goals`, body);
      await mutate();
      await fetchPatientData();
    } catch (error) {
      console.error("Error adding goal:", error);
    }
  };

  const handleChangeGoal = (index, value) => {
    const currentGoals = [...goals];
    currentGoals[index].stat = value.ty;
    setGoals(currentGoals);
    handleSave(index);
  };

  const handleChangeGoalComparison = (index, value) => {
    const currentGoals = [...goals];
    currentGoals[index].op = value;
    setGoals(currentGoals);
    handleSave(index);
  }

  const handleChangeGoalValue = (index, value) => {
    const currentGoals = [...goals];
    currentGoals[index].value = value;
    setGoals(currentGoals);
    handleSave(index);
  };

  const handleDeleteGoal = async (index) => {
    const currentGoals = [...goals];
    currentGoals.splice(index, 1);
    try {
      await deleteApi(`${PATIENT_ROUTE}/${patientId}/goals`, { id: goals[index].id });
      await mutate();
    } catch (error) {
      console.error("Error deleting goal:", error);
    }
    setGoals(currentGoals);
  };

  const handleSave = async (index) => {
    if (index === goals.length - 1) {
      return;
    }
    const goal = goals[index];
    const body = {
      ...goal,
      value: parseInt(goal.value) || 0,
    }
    try {
      await postApi(`${PATIENT_ROUTE}/${patientId}/goals/update`, body);
    } catch (error) {
      console.error("Error saving goal:", error);
    }
  }

  // note(angela): is this still needed? there isn't a min or max in the goal object
  const isError = (goal) => {
    return goal?.value < goal?.min || goal?.value > goal?.max;
  };

  const fetchGoalOptions = async () => {
    try {
      const goalOptionsResponse = await GetApiParam(`${PATIENT_ROUTE}/${patientId}/goaloptions`);
      setGoalOptions(goalOptionsResponse.data?.options);
    } catch (error) {
      console.error("Error fetching goal options:", error);
    }
  }

  useEffect(() => {
    fetchGoalOptions();
  }, [patientId]);

  const opOptions = ['LessThan', 'GreaterThan', 'Equal']


  return (
    <Box mt={3}>
      <Box>
        <Box
          className="title-with-border text-primery text--left"
          position="relative"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography component="h4" className="title">
            Goals
          </Typography>
        </Box>
      </Box>

      <Box mt={3}>
        {/* Display all current goals (all except last one) */}
        {goals.slice(0, -1).map((goal, index) => (
          <Grid container spacing={3} key={index}>
            <GoalInput
              key={index}
              index={index}
              goalOptions={goalOptions}
              goals={goals}
              handleChangeGoal={handleChangeGoal}
              handleChangeGoalComparison={handleChangeGoalComparison}
              handleChangeGoalValue={handleChangeGoalValue}
              opOptions={opOptions}
              isError={isError}
            />
            <Grid item lg={0.5}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                pb={2.5}
                onClick={() => handleDeleteGoal(index)}
              >
                <Cancel className="delete-icon" htmlColor="danger" />
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
      {/* Display the row to add a new goal */}
      <Box mt={3} className="text-primery text--left">
        <Typography component="h4" mb={1}>
          Add New Goal
        </Typography>
        <Grid container spacing={3}>
          <GoalInput
            key={goals.length - 1}
            index={goals.length - 1}
            goalOptions={goalOptions}
            goals={goals}
            handleChangeGoal={handleChangeGoal}
            handleChangeGoalComparison={handleChangeGoalComparison}
            handleChangeGoalValue={handleChangeGoalValue}
            opOptions={opOptions}
            isError={isError}
          />
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              height="100%"
            >
              <Button
                variant="contained"
                color="info"
                className="btn-primary"
                sx={{
                  fontWeight: 'bold',
                  '&.Mui-disabled': {
                    backgroundColor: '#91D9B9',
                  },
                }}
                disabled={!goals[goals.length - 1].stat || !goals[goals.length - 1].op || !goals[goals.length - 1].value}
                onClick={() => handleAddGoal(goals[goals.length - 1])}
              >
                Add Goal
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};


const GoalInput = ({
  index,
  goalOptions,
  goals,
  handleChangeGoal,
  handleChangeGoalComparison,
  handleChangeGoalValue,
  opOptions,
  isError
}) => {
  const goal = goals[index];

  return (
    <>
      {/* Goal options */}
      <Grid item lg={4} width={1 / 3}>
        <Autocomplete
          id="combo-box-demo"
          options={goalOptions}
          getOptionLabel={(option) => option.name}
          value={goalOptions.find((option) => option.ty === goal?.stat) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                input: {
                  color: "#91D9B9",
                },
                button: {
                  color: "#91D9B9 !important",
                },
              }}
            />
          )}
          onChange={(_, value) => handleChangeGoal(index, value)}
          sx={{
            borderColor: "var(--white)",
            color: "var(--primeryColor)",
            border: "1px solid var(--whiteLight)",
            borderRadius: "5px",
            outline: "none !important",
          }}
        />
      </Grid>
      {/* Less Than, Greater Than, Equals */}
      <Grid item lg={2} width={1 / 5}>
        <Box className="form-group">
          <Select
            value={goal?.op || ''}
            onChange={(event) => handleChangeGoalComparison(index, event?.target.value)}
            displayEmpty
            fullWidth
            sx={{
              borderColor: "var(--white)",
              color: "var(--primeryColor)",
              border: "1px solid var(--whiteLight)",
              borderRadius: "5px",
              outline: "none !important",
              textAlign: "left",
            }}
          >
            {opOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
      {/* Value input */}
      <Grid item lg={3.5} width={1 / 5}>
        <Box className="form-group">
          <TextField
            value={goal?.value || ''}
            type="number"
            error={isError(goal)}
            disabled={!goal?.stat}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box color="#777">{goalOptions.find((option) => option.ty === goal?.stat)?.unit}</Box>
                </InputAdornment>
              ),
            }}
            onChange={(x) =>
              handleChangeGoalValue(index, x?.target?.value)
            }
            sx={{
              border: isError(goal)
                ? "none"
                : "1px solid var(--whiteLight)",
              input: {
                width: "100%",
                color: "var(--primeryColor)",
                borderRadius: "5px",
                padding: "16px 14px",
              },
            }}
          />
        </Box>
      </Grid>
    </>
  )
}

export default SetGoalComponent;
