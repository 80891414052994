import React, { useEffect, useState } from "react";
import { Box, Button, Grid, InputLabel, FormControl, MenuItem, Modal, Typography, Select } from "@mui/material";
import "./AddControllers.css";
import "../../../Assets/Css/Responsive.css";
import { Controller, useForm } from "react-hook-form";
import Input from "../../../Components/InputComponent/Input";
import ButtonComponent from "../../../Components/ButtonComponent/ButtonComponent";
import { GetApiParam, postApi } from "../../../Api/Api";
import toast from "react-hot-toast";
import { DEVICE_ROUTE, USER_ROUTE } from "../../../Api/Routes";

const AddControllers = (props) => {
  const { handleClose, open, editValue, controllerId, AddUpdateController } =
    props;

  const [selectedPhysician, setSelectedPhysician] = useState(null);
  const [physicianData, setPhysicianData] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchPhysicians = async () => {
      try {
        const responseUsers = await GetApiParam(USER_ROUTE, { role: "Physician" });
        setPhysicianData(responseUsers?.data?.users || []);
      } catch (err) {
        console.error("Error fetching available physicians:", err);
      }
    };
    fetchPhysicians();
    if (editValue) {
      const fetchControllerDetails = async () => {
        try {
          const response = await GetApiParam(`${DEVICE_ROUTE}/${controllerId}`);
          const controllerDetails = response.data;
          setValue("name", controllerDetails.name);
          setValue("mac_address", controllerDetails.mac_address);
          setSelectedPhysician(controllerDetails?.physician_id || null);

        } catch (error) {
          console.error("Error fetching controller details:", error);
        }
      };
      fetchControllerDetails();
    }
  }, [editValue, controllerId, setSelectedPhysician, setPhysicianData, setValue]);

  const handleSelectedPhysician = (event) => {
    if (event.target.value !== null) {
      const physician = physicianData.find((p) => p.id === event.target.value);
      setSelectedPhysician(physician.id);
    } else {
      setSelectedPhysician(null);
    }
  }

  const onSubmit = async (data, event) => {
    console.log("fuck you", JSON.stringify(data));
    event.preventDefault();
    const endpoint = editValue
      ? `${DEVICE_ROUTE}/${controllerId}`
      : DEVICE_ROUTE;

    const formData = {
      //NOTE(Kaitlyn): This value isn't included in the form data, and the only way I can get it to sometimes do so breaks the UI.
      physician_id: selectedPhysician,
      ...data,
    };

    try {
      const responseData = await postApi(
        endpoint,
        editValue ? { ...formData, id: controllerId } : formData
      );
      if (responseData?.data) {
        reset();
        handleClose();
        AddUpdateController();
        toast.success(editValue ? "Controller Updated" : "Controller Added");
      } else {
        toast.error(
          editValue ? "Controller Updated Failed" : "Controller Added Failed"
        );
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        className="profile-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-shadow">
          <Box className="modal-header">
            <Typography
              id="modal-modal-title"
              className="text-primery"
              variant="h5"
              component="h5"
            >
              Add Controller
            </Typography>
            <Button onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  opacity="0.2"
                  d="M32.0002 3.138L28.8632 0L16.4242 12.439L3.98517 0L0.847168 3.138L13.2862 15.577L0.847168 28.016L3.98517 31.154L16.4242 18.714L28.8632 31.153L32.0012 28.015L19.5612 15.577L32.0002 3.138Z"
                  fill="white"
                />
              </svg>
            </Button>
          </Box>
          <Box
            className="modal-body"
            id="modal-modal-description"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="form-group">
                    <Input
                      label="Controller Name"
                      placeholder="Apollo"
                      type="text"
                      name="name"
                      requiredField="requiredField"
                      register={register}
                      validationRules={{
                        required: "Controller Name is required",
                      }}
                    />
                    {errors.name && (
                      <p className="error-message">{errors.name.message}</p>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="form-group">
                    <Input
                      label="Mac Address"
                      placeholder="Pitts"
                      type="text"
                      name="mac_address"
                      requiredField="requiredField"
                      register={register}
                      validationRules={{
                        required: "Mac Address is required",
                      }}
                    />
                    {errors.mac_address && (
                      <p className="error-message">
                        {errors.mac_address.message}
                      </p>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="form-group">
                    <InputLabel shrink className="input-label">
                      Select Physician
                    </InputLabel>
                    <FormControl>
                      <Select displayEmpty={true}
                              name="physician_id" 
                              value={selectedPhysician} 
                              onChange={handleSelectedPhysician}>
                        <MenuItem value={null}>None</MenuItem>
                        {physicianData &&
                          physicianData.map((physician) => (
                            <MenuItem
                              key={physician.id}
                              value={physician.id}
                            >
                              {physician.first_name}{" "}
                              {physician.last_name}
                            </MenuItem>
                          ))}
                      </Select>
                      {/* <Controller
                        name="physician_id"
                        control={control}
                        value={"argh"}
                        onChange={handleSelectedPhysician}
                        render={({ field }) => (
                          <Select {...field}>

                          </Select>
                        )}
                      /> */}
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Box className="form-group">
                    <Box className="btn-center">
                      <ButtonComponent
                        type="submit"
                        buttonText={
                          editValue ? "Update Controller" : "Add Controller"
                        }
                        buttonVariant="contained"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddControllers;

const style = {
  position: "absolute",
  top: "50%",
  left: 15,
  right: 15,
  transform: "translateY(-50%)",
  maxWidth: 1000,
  bgcolor: "#042835",
  color: "#ffffff",
  boxShadow: 24,
  p: 4,
  margin: "1rem auto",
  borderRadius: 5,
};
