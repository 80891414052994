import React from 'react'
import "./DashboardCard.css"
import { Box, Typography } from '@mui/material'

const DashboardCard = (props) => {
  const{customStyle, title, icon, count} = props
    return (
        <Box className="dashboard-card-container " sx={{...customStyle}} >
            <Typography component="h5" className='card-title' >
               {title}
            </Typography>
            <Box className="card-content">
                <Typography className='count-number'component="h2">{count}</Typography>
                <img src={icon} alt='' />
            </Box>
        </Box>
    )
}

export default DashboardCard