import React, { createContext, useContext, useState } from 'react';

const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [selectedDevice, setSelectedDevice] = useState("2c:cf:67:28:30:7d");
  const [deviceLabel, setDeviceLabel] = useState("Device 1");

  

  return (
    <DeviceContext.Provider value={{ selectedDevice, setSelectedDevice, setDeviceLabel, deviceLabel}}>
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error("useDevice must be used within a DeviceProvider");
  }
  return context;
};
