import React, { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { GetApiParam } from "../../Api/Api";
import SetGoalComponent from "../SetGoalComponent/SetGoalComponent";
import { AddPatients } from "./components";

import "../../Components/SelectComponent/SelectComponent.css"

import {
  PatientDetailsCard,
  ProgressCard,
  TimelineCard,
  EventsCard,
} from "./components";
import { PATIENT_ROUTE, USER_ROUTE } from "../../Api/Routes";
import dayjs from "dayjs";

const PatientsDetails = () => {
  const { id } = useParams();

  const [patientDetails, setPatientDetails] = useState();
  const [physicianData, setPhysicianData] = useState();
  const [openEditPatientDetails, setOpenEditPatientDetails] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [eventsDefault, setEventsDefault] = useState();
  const [progressDefaultStart, setProgressDefaultStart] = useState();
  const [progressDefaultEnd, setProgressDefaultEnd] = useState();
  const [timelineDefaultStart, setTimelineDefaultStart] = useState();
  const [timelineDefaultEnd, setTimelineDefaultEnd] = useState();

  const fetchPatientDetails = async () => {
    try {
      const patientResponse = await GetApiParam(`${PATIENT_ROUTE}/${id}`);
      const physicianResponse = await GetApiParam(USER_ROUTE, { role: "Physician", id: patientResponse?.data?.physician_id });

      const patient = patientResponse.data;
      const physicians = physicianResponse.data.users;

      const physician = physicians.find((user) => user.id === patient.physician_id);
      patient.physician_name = `${physician?.first_name} ${physician?.last_name}`;

      setEventsDefault(dayjs(patient.events_default, "YYYYMMDD").toDate());
      setProgressDefaultStart(dayjs(patient.progress_default_start, "YYYYMMDD").toDate());
      setProgressDefaultEnd(dayjs(patient.progress_default_end, "YYYYMMDD").toDate());
      setTimelineDefaultStart(dayjs(patient.timeline_default_start, "YYYYMMDDHHmm").toDate());
      setTimelineDefaultEnd(dayjs(patient.timeline_default_end, "YYYYMMDDHHmm").toDate());

      console.log(JSON.parse(JSON.stringify(patient)));
      setPatientDetails(patient);
      setPhysicianData(physicians);
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  useEffect(() => {
    fetchPatientDetails();
  }, [id]);

  const handleOpenEditPatient = () => {
    setOpenEditPatientDetails(true);
  }

  const handleCloseEditPatient = () => {
    setOpenEditPatientDetails(false);
  }

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  return (
    <Box>
      <Box className="my-patients-topbar" mt={3}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <Tabs
            className="title"
            component="h4"
            value={currentTabIndex}
            onChange={handleTabChange}
            TabIndicatorProps={{
              sx: { backgroundColor: "#91D9B9" },
            }}
            sx={{
              ".MuiButtonBase-root": {
                color: "#91D9B9 !important",
              },
              ".Mui-disabled": {
                color: "#9e9e9e !important",
              },
            }}
          >
            <Tab label="Overview" />
            <Tab label="Goal Setup" />
          </Tabs>
          {
            currentTabIndex === 0 && <Button
              variant="contained"
              color="info"
              className="btn-primary"
              sx={{ fontWeight: 'bold' }}
              onClick={handleOpenEditPatient}
            >
              Edit Patient
            </Button>
          }
        </Box>
        {/* Edit patient modal */}
        <AddPatients
          open={openEditPatientDetails}
          handleClose={handleCloseEditPatient}
          editValue={true}
          patientId={patientDetails?.id}
          AddUpdatePatient={fetchPatientDetails}
          physicianData={physicianData || []}
        />
      </Box>
      {patientDetails && currentTabIndex === 0 && (
        <>
          {/* Patient Card */}
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item sm={12} xs={12} md={5} lg={4}>
                <PatientDetailsCard
                  {...patientDetails}
                  controller={patientDetails?.device_name}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={7} lg={8}>
                <ProgressCard
                  patientId={id}
                  defaultStart={progressDefaultStart}
                  defaultEnd={progressDefaultEnd}
                />
              </Grid>
            </Grid>
          </Box>
          <EventsCard
            patientId={id}
            pinnedEventsData={patientDetails?.pinned_events}
            defaultDay={eventsDefault}
            graphDefaultStart={progressDefaultStart}
            graphDefaultEnd={progressDefaultEnd}
          />
          <TimelineCard
            patientId={id}
            defaultStart={timelineDefaultStart}
            defaultEnd={timelineDefaultEnd}
          />
        </>
      )}

      {/* Goals Setup Tab */}
      {patientDetails && currentTabIndex === 1 && (
        <SetGoalComponent patientId={patientDetails?.id} goals={patientDetails?.goals} mutate={fetchPatientDetails} />
      )}
    </Box>
  );
};

export default PatientsDetails;