import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Stack,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddControllers from "../Add controllers/AddControllers";
import { deleteApi } from "../../../Api/Api";
import ConfirmationDialog from "../../../Components/ConfirmationDialog";
import { useSearch } from "../../../Context/SearchContext";
import toast from "react-hot-toast";
import { format, parseISO } from "date-fns";
import { DEVICE_ROUTE } from "../../../Api/Routes";

const ControllerTable = ({ controllerData, AddUpdateController }) => {
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [controllerId, setControllerId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const { searchQuery } = useSearch();
  const [filteredData, setFilteredData] = useState(controllerData);

  useEffect(() => {
    if (searchQuery) {
      const filteredResults = controllerData.filter((item) =>
        Object.values(item).some((value) => String(value).includes(searchQuery))
      );
      setFilteredData(filteredResults);
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, controllerData]);

  const displayData = searchQuery ? filteredData : controllerData;

  const handleOpen = (id) => {
    setEditValue(true);
    setControllerId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditValue(false);
    setControllerId(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = (id) => {
    setControllerId(id);
    setEditValue(false);
    setOpenDialog(true);
  };

  const handleUpdate = async () => {
    setOpenDialog(false);
    const endpoint = `${DEVICE_ROUTE}/${controllerId}`;

    try {

      const responseData = await deleteApi(endpoint);

      if (responseData.data) {
        AddUpdateController();
        toast.success("Controller Deleted");
      } else {
        toast.error("Controller Deleted Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box className="table-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Device Name</StyledTableCell>
              <StyledTableCell>Mac Address</StyledTableCell>
              <StyledTableCell>Assigned Patient</StyledTableCell>
              <StyledTableCell>Last Message Time</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((controller) => (
              <StyledTableRow key={controller.id}>
                <StyledTableCell component="th" scope="row">
                  {controller.name}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {controller.mac_address}
                </StyledTableCell>
                <StyledTableCell>
                  {controller.patient_name || <i>Unassigned</i>}
                </StyledTableCell>
                <StyledTableCell>
                  {controller.last_message_time
                    ? format(
                        parseISO(controller.last_message_time),
                        "MM-dd-yy HH:mm"
                      )
                    : "N/A"}
                </StyledTableCell>
                <StyledTableCell>
                  {controller.is_online ? (
                    <Chip label="Online" color="success" />
                  ) : (
                    <Chip label="Offline" color="error" />
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Stack
                    direction="row"
                    gap={2}
                  >
                    <Button
                      variant="contained"
                      color="info"
                      className="btn-primary"
                      onClick={() => handleOpen(controller.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      className="btn-delete"
                      onClick={() => handleDelete(controller.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        content={`Confirm Delete Controller`}
        onConfirm={handleUpdate}
        onClose={handleDialogClose}
      />
      <AddControllers
        open={open}
        handleClose={handleClose}
        editValue={editValue}
        controllerId={controllerId}
        AddUpdateController={AddUpdateController}
      />
    </Box>
  );
};

export default ControllerTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#042835",
    color: "rgb(255,255, 255, 70%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
    fontWeight: 700,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#03212b",
    color: "rgb(255,255, 255, 40%)",
    borderBottom: "1px solid #021A22",
    fontFamily: "Acumin Pro",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
